import React from "react";

const Filler = (props) => {
  return (
    <div
      className={props.percentage >= 50 ? "fillerSus" : "fillerFail"}
      style={{ width: `${props.percentage}%` }}
    ></div>
  );
};

export default Filler;
