import React, { Fragment, useEffect, useState } from "react";
import Timeline from "./TimelineCV";
import CVForm from "./CVCard";
import "./CVC.css";
import TimelineComponent from "../auth/components/Timeline";
import { timelines } from "../auth/utils";

const propsValidator = (check2fa) => {
  if (!check2fa) {
    window.location = "/phonecheck";
  }
};
const DreamJob = ({data}) => {
  const [selectTimeline, setSelected] = useState(4);
  useEffect(() => {
    if(Object.values(data).length === 0) return;
    propsValidator(data.check2fa);

    //eslint-disable-next-line
  }, [data.check2fa]);
  return (
    <Fragment>
      <div className="container-login">
        <div className="container-blue" style={{ padding: "50px" }}>
          <TimelineComponent
            timelines={timelines}
            selectTimeline={selectTimeline}
          />
        </div>
        <div className="rounded-lg container-form">
          <CVForm name={data.name} />
        </div>
      </div>
    </Fragment>
  );
};

export default DreamJob;
