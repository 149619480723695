import React, { useEffect } from "react";
import "../dreamjob/dreamjob.css";
import "../auth/auth.css";
import { useState } from "react";
import { timelines } from "../auth/utils";
import TimelineComponent from "../auth/components/Timeline";
import English from "./English";

const propsValidator = (check2fa) => {
  if (!check2fa) {
    window.location = "/phonecheck";
  }
};
const EnglishStep = ({data}) => {
  const [selectTimeline, setSelectedTimeline] = useState(2);
  
  useEffect(() => {
    if(Object.values(data).length === 0) return;
    propsValidator(data.check2fa);

    //eslint-disable-next-line
  }, [data.check2fa]);

  return (
    <div className="container-login">
      <div className="container-blue" style={{ padding: "50px" }}>
        <TimelineComponent
          timelines={timelines}
          selectTimeline={selectTimeline}
        />
      </div>
      <div className="rounded-lg container-form">
        <English data={data} />
      </div>
    </div>
  );
};

export default EnglishStep;
