import React, { Component } from "react";
class Timeline extends Component {
  render() {
    return (
      <div className="containerTimeJob">
        <ul className="progressbar jobTittles">
          <li className="manager active">Sign up</li>
          <li className="active manager">Your dream job</li>
          <li className="manager active">Skills</li>
          <li className="manager active">English</li>
          <li id="active" className="manager active">
            CV Load
          </li>
          <li className="manager">ID check</li>
          <li className="manager">Ready to be hired</li>
        </ul>
      </div>
    );
  }
}
export default Timeline;
