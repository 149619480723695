const dates = {
	year: [],
	month: [],
	day: [],
};

for (let i = new Date().getFullYear(); i >= 1900; i--) {
	dates.year.push(i);
}
for (let i = 1; i <= 31; i++) {
	dates.day.push(i);
}
for (let i = 1; i <= 12; i++) {
	dates.month.push(i);
}

export default dates;
