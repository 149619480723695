import React, { Component, Fragment } from "react";
import TimelineEnglish from "./TimelineEnglish";
import EnglishCard from "./EnglishCard";
import EnglishMailSend from "./EnglishMailSend";

class English extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailSend: false,
    };
  }
  async componentDidMount() {
    const response = await fetch("/api/app/data/english");
    const data = await response.json();
    if (data.ok) {
      this.setState({
        mailSend: true,
      });
    }
  }
  handleClick = () => {
    fetch("/api/mails/secondstep");
    fetch("/api/app/createEnglish");
    this.setState({
      mailSend: true,
    });
  };
  handleReady = (e) => {
    fetch("/api/mails/noenglish");
    fetch("api/app/noenglish");
    window.location = "/checkid";
  };

  render() {
    return (
      <Fragment>
        <div className="columnJob">
          {/* <TimelineEnglish /> */}
          <div className="">
            {this.state.mailSend === true ? (
              <EnglishMailSend data={this.props.data} handleReady={this.handleReady} />
            ) : (
              <EnglishCard
                name={this.props.data.name}
                manageClick={this.handleClick}
                handleReady={this.handleReady}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default English;
