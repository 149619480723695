import React, { Component } from "react";
import { Form } from "react-bootstrap";

class PasswordCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordConfirm: "",
      valid: "",
      sent: false,
    };
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "password") {
      this.setState({
        [name]: value,
      });
    } else {
      this.setState({
        [name]: value,
        valid: this.state.password === value ? "" : "Password do not match",
      });
    }
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const passwordPattern = new RegExp(
      // eslint-disable-next-line
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    );
    if (this.state.password === "" || this.state.passwordConfirm === "") {
      this.setState({
        valid: "Both fields are required",
      });
    } else if (!passwordPattern.test(this.state.password)) {
      this.setState({
        valid:
          "Please enter a  password with at least eight characters, at least one letter, one number and one special character.",
      });
    } else if (this.state.password === this.state.passwordConfirm) {
      const dataFetch = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: this.state.password,
          passwordConfirm: this.state.passwordConfirm,
        }),
      };

      const token = await window.location.href.split("/").pop();

      const response = await fetch(
        "/api/account/recover-password/" + token,
        dataFetch
      );
      const data = await response.json();
      if (data.ok) {
        window.location = "/login";
      } else {
        this.setState({
          valid: "Something went wrong try again later.",
        });
      }
    }
  };
  handleClick = async (event) => {
    event.preventDefault();
  };
  render() {
    return (
      <div className="cardContainerPass">
        <h2 className="title passTitle">RESET PASSWORD</h2>
        <Form className="authForm" onSubmit={(e) => this.handleSubmit(e)}>
          <Form.Group className="formInput">
            <Form.Control
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              required
              value={this.state.password}
              onChange={(e) => this.handleChange(e)}
            />
          </Form.Group>
          <Form.Group className="formInput">
            <Form.Control
              type="password"
              id="passwordConfirm"
              name="passwordConfirm"
              placeholder="Repeat Password"
              required
              value={this.state.passwordConfirm}
              onChange={(e) => this.handleChange(e)}
            />
          </Form.Group>
          {this.state.valid ? (
            <p className="warning">{this.state.valid}</p>
          ) : null}
          <button className="col-5 btn btn-m buttonSecondary">
            Save and Sign In
          </button>
        </Form>
      </div>
    );
  }
}

export default PasswordCard;
