import React, { Component, Fragment } from "react";
import Progressbar from "./ProgressBar";
import Radialbar from "./Radialbar";

class Stats extends Component {
  reducer = (accumulator, currentValue) =>
    accumulator +
    parseInt(
      currentValue.score === "Not taken" || currentValue.score === "Pending"
        ? 0
        : currentValue.score,
      10
    );

  render(props) {
    if (!this.props.skills) window.location = "/skills";
    return (
      <Fragment>
        <div className="cardJobDash">
          <p className="titleStats">Overall</p>
          <div className="rowStats">
            <div className="columnStats">
              <div className="rowOverall">
                <span style={{ maxWidth: "40px" }}>
                  {String(
                    Math.floor(
                      this.props.skills.reduce(this.reducer, 0) /
                        this.props.skills.length
                    )
                  )}
                </span>

                <Progressbar
                  percentage={Math.floor(
                    this.props.skills.reduce(this.reducer, 0) /
                      this.props.skills.length
                  )}
                />
              </div>
              {this.props.skills.map((elem) => {
                return (
                  <div key={elem._id} className="rowOverall">
                    <span style={{ maxWidth: "40px" }}>
                      {elem.score === "Not taken" || elem.score === "Pending"
                        ? 0
                        : Math.floor(elem.score)}
                    </span>
                    <Progressbar
                      percentage={
                        elem.score !== "Not taken" ? Math.floor(elem.score) : 0
                      }
                    />
                  </div>
                );
              })}
            </div>
            <Radialbar
              percentage={Math.floor(
                this.props.skills.reduce(this.reducer, 0) /
                  this.props.skills.length
              )}
            />
          </div>
          <a
            className="btn btn-m buttonPrimaryDash paddingIdBtn buttomAdmin cvButton"
            href={`/cvload`}
          >
            Edit CV Text
          </a>
        </div>
      </Fragment>
    );
  }
}

export default Stats;
