import React, { Component, Fragment } from "react";
import TimelineSkills from "./TimelineSkills";
import SkillResend from "./SkillResend";
import SendTestCard from "./SendTestCard";

class SendTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skill: "",
      pending: false,
      skillFull: {},
    };
  }
  async componentDidMount() {
    try {
      const token = await window.location.href.split("/").pop();
      const response = await fetch("/api/app/data/" + token);
      const data = await response.json();

      this.setState({
        skill: data.skill.skill,
        pending: data.skill.score === "Pending",
        skillFull: data.skill,
      });
    } catch (error) {
      console.log(error);
      //window.location = "/dashboard";
    }
  }
  render() {
    return (
      <Fragment>
        <div className="columnJob">
          {/* <TimelineSkills /> */} 
          <div className="whitebg skillsBg">
            {this.state.pending ? (
              <SkillResend
                name={this.props.data.name}
                skill={this.state.skill}
                id={this.state.skillFull._id}
              />
            ) : (
              <SendTestCard
                name={this.props.data.name}
                skill={this.state.skill}
                id={this.state.skillFull._id}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SendTest;
