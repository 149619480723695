import React, { Component } from "react";
import { Form } from "react-bootstrap";

class LoginCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      valid: "",
      active: true,
    };
  }
  handleLogin = async (event) => {
    this.setState({
      valid: "",
    });
    const emailPattern = new RegExp(
      // eslint-disable-next-line
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );
    const passwordPattern = new RegExp(
      // eslint-disable-next-line
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    );
    event.preventDefault();
    if (this.state.email === "" || this.state.password === "") {
      this.setState({
        valid: "Missing email or password",
      });
    } else if (
      emailPattern.test(this.state.email) &&
      passwordPattern.test(this.state.password)
    ) {
      const dataFetch = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: this.state.email.toLowerCase(),
          password: this.state.password,
        }),
      };
      const response = await fetch("/api/account/login", dataFetch);
      const data = await response.json();
      if (data.ok) {
        sessionStorage.setItem("logged", true);
        if (data.typeOfUser === "admin") {
          window.location = "/admin";
        } else if (!data.token2fa) {
          window.location = "/phoneCheck";
        } else if (!data.job) {
          window.location = "/dreamjob";
        } else if (!data.skill) {
          window.location = "/skills";
        } else if (data.english) {
          window.location = "/english";
        } else if (data.cv) {
          window.location = "/CVload";
        } else if (!data.validationPhoto) {
          window.location = "/checkid";
        } else {
          window.location = "/dashboard";
        }
      } else {
        if (data.error === "user not confirmed") {
          this.setState({
            active: false,
          });
        } else {
          this.setState({
            valid: data.error,
          });
        }
      }
    } else if (!emailPattern.test(this.state.email)) {
      this.setState({
        valid: "Please enter a valid email address",
      });
    } else if (!passwordPattern.test(this.state.password)) {
      this.setState({
        valid:
          "Please enter a password with at least eight characters, at least one letter, one number and one special character",
      });
    }
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  handleClick = async (e) => {
    this.setState({
      valid: "",
    });

    await fetch("/api/mails/resendtoken/" + this.state.email);
    this.setState({
      valid: "Resent!",
    });
  };
  render(props) {
    if (this.state.active) {
      return (
        <div className="cardContainer">
          <h2 className="title">Sign In</h2>
          <Form className="authForm" onSubmit={this.handleLogin}>
            <Form.Group>
              <Form.Label className="label">Email</Form.Label>
              <Form.Control
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                required
                onChange={(e) => this.handleChange(e)}
                value={this.state.email}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="label">Password</Form.Label>
              <Form.Control
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                required
                onChange={(e) => this.handleChange(e)}
                value={this.state.password}
              />
            </Form.Group>
            {this.state.valid ? (
              <p className="warning">{this.state.valid}</p>
            ) : null}
            <button className="btn btn-m buttonPrimary">Sign in</button>
          </Form>
          <p className="bottomText">
            Don't have an account yet?
            <a href="/register"> Sign Up</a>{" "}
          </p>
          <p className="bottomText">
            <a href="/forgot-password">Forgot Password?</a>{" "}
          </p>
        </div>
      );
    } else {
      return (
        <div className="cardContainerPass">
          <h2 className="title passTitle">SIGN IN</h2>
          <p className="extraText">
            You have not confirmed your account.
            <br />
            An email have been sent to {this.state.email} with further
            instructions about how to validate your account.
            <br />
            <br />
            Please remember to look in important, promotions and spam tags.
          </p>
          <br />
          <p className="smallText">Didn't receive a message?</p>
          <button
            className="col-5 btn btn-m buttonSecondary"
            onClick={(e) => this.handleClick(e)}
          >
            Resend Email
          </button>
          {this.state.valid ? (
            <p className="warning">{this.state.valid}</p>
          ) : null}
        </div>
      );
    }
  }
}

export default LoginCard;
