import React, { Component, Fragment } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "../views/auth/Login";
import Confirm from "../views/auth/Confirm";
import Register from "../views/auth/Register";
import FPassword from "../views/password/FPassword";
import RPassword from "../views/password/RPassword";
import Terms from "../views/terms/Terms";
import Checkid from "../views/checkID/CheckID";
import DreamJob from "../views/dreamjob/Dreamjob";
import Skills from "../views/skills/Skills";
import English from "../views/english/English";
import Profile from "../views/profile/Profile";
import Admin from "../views/admin/Admin";
import Dashboard from "../views/dashboard/Dashboard";
import EditSkill from "../views/skills/EditSkill";
import PhoneCheck from "../views/phoneCheck/PhoneCheck";
import ResendSkill from "../views/skills/SendTest";
import CreatePartner from "../views/admin/CreatePartner";
import CVgen from "../views/CV/CVGen";
import EnglishStep from "../views/english/EnglishStep";

class Router extends Component {
  render(props) {
    const auth = sessionStorage.getItem("logged") ? true : false;
    return (
      <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
        {auth ? (
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                return <Redirect to="/dashboard" />;
              }}
            />
            <Route
              exact
              path="/register"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              exact
              path="/terms"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              path="/confirmAccount"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              exact
              path="/login"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              exact
              path="/forgot-password"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              path="/recover-password"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              exact
              path="/checkid"
              render={(props) => <Checkid {...props} data={this.props.data} />}
            />
            <Route
              exact
              path="/dreamJob"
              render={(props) => <DreamJob {...props} data={this.props.data} />}
            />
            <Route
              exact
              path="/skills"
              render={(props) => <Skills {...props} data={this.props.data} />}
            />
            <Route
              exact
              path="/cvLoad"
              render={(props) => <CVgen {...props} data={this.props.data} />}
            />
            <Route
              exact
              path="/english"
              render={(props) => <EnglishStep {...props} data={this.props.data} />}
            />
            <Route exact path="/profile" render={(props) => <Profile {...props} data={this.props.data} />} />
            <Route
              exact
              path="/admin"
              render={(props) => <Admin {...props} data={this.props.data} />}
            />
            <Route
              exact
              path="/admin/partner"
              render={(props) => (
                <CreatePartner {...props} data={this.props.data} />
              )}
            />
            <Route
              exact
              path="/dashboard"
              render={(props) => (
                <Dashboard {...props} data={this.props.data} />
              )}
            />
            <Route
              exact
              path="/phonecheck"
              render={(props) => (
                <PhoneCheck {...props} data={this.props.data} />
              )}
            />
            <Route
              path="/edit-skill"
              render={(props) => (
                <EditSkill {...props} data={this.props.data} />
              )}
            />

            <Route
              path="/resendskill"
              render={(props) => (
                <ResendSkill {...props} data={this.props.data} />
              )}
            />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/register" render={() => <Register />} />
            <Route exact path="/terms" component={Terms} />
            <Route path="/confirmAccount" component={Confirm} />
            <Route exact path="/forgot-password" component={FPassword} />
            <Route path="/recover-password" component={RPassword} />
            <Route
              exact
              path="/login"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              exact
              path="/checkid"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              exact
              path="/dreamJob"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              exact
              path="/skills"
              render={() => {
                return <Redirect to="/" />;
              }}
            />

            <Route
              exact
              path="/Cvload"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              exact
              path="/english"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              exact
              path="/profile"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              exact
              path="/admin"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              exact
              path="/dashboard"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              exact
              path="/phonecheck"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
            <Route
              path="/edit-skill"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
          </Switch>
        )}
      </BrowserRouter>
    );
  }
}

export default Router;
