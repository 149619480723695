import React from "react";
import Filler from "./Filler";

const ProgressBar = (props) => {
  return (
    <div
      className={
        props.percentage >= 50
          ? "progressBar progressBarSus"
          : "progressBar progressBarFail"
      }
    >
      <Filler percentage={props.percentage} />
    </div>
  );
};

export default ProgressBar;
