import React, { Component, Fragment } from "react";
import "./profile.css";
import Notifications from "./Notifications";
import ProfileCard from "./ProfileCard";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      address: "",
      postcode: "",
      countryCode: "",
      phone: "",
      photo: "",
      country: "Select your country",
      newPassword: "",
      newPasswordConfirm: "",
      year: "year",
      month: "month",
      day: "day",
      tempCountry: "",
      valid: "",
      avatar: "",
      newsletter: true,
      jobsWithinRange: true,
      lowerPaidJobs: true,
      training: true,
      surveysAndResearch: true,
      remoteWorkTips: true,
      file: "",
    };
  }
  async componentDidMount() {
    const response = await fetch("/api/app/currentFull");
    const data = await response.json();
    if (data.user && data.ok) {
      const date = new Date(data.user.DOB);
      this.setState({
        avatar: data.user.avatar,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        email: data.user.email,
        password: data.user.password,
        address: data.user.address,
        postcode: data.user.postcode,
        countryCode: data.user.countryCode,
        phone: data.user.phone,
        country: data.user.country,
        year: date.getFullYear(),
        month: date.getUTCMonth() + 1,
        day: date.getUTCDate(),
        tempCountry: data.user.country + "/" + data.user.countryCode,
        newsletter: data.user.config.newsletter,
        jobsWithinRange: data.user.config.jobsWithinRange,
        lowerPaidJobs: data.user.config.lowerPaidJobs,
        training: data.user.config.training,
        surveysAndResearch: data.user.config.surveysAndResearch,
        remoteWorkTips: data.user.config.remoteWorkTips,
      });
    } else {
      window.location = "/login";
    }
  }
  handleSelect = (event) => {
    const { name, value } = event.target;
    const arrvalue = value.split("/");
    this.setState({
      [name]: value,
      country: arrvalue[0],
      countryCode: arrvalue[1],
    });
  };
  handleChange = async (event) => {
    const { name, value, type, checked } = event.target;
    type === "checkbox"
      ? await this.setState({
          [name]: checked,
        })
      : await this.setState({
          [name]: value,
        });
  };
  handleUpload = (event) => {
    const { name, files } = event.target;
    const data = new FormData();
    data.append("photo", files[0]);
    this.setState({
      [name]: files[0],
      data,
    });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    let today = new Date();
    let ageString =
      "" + this.state.year + "-" + this.state.month + "-" + this.state.day;
    let birthDate = new Date(ageString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    let validAge = age >= 18;

    const emailPattern = new RegExp(
      // eslint-disable-next-line
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );

    const passwordPattern = new RegExp(
      // eslint-disable-next-line
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    );
    if (
      this.state.email === "" ||
      this.state.firstName === "" ||
      this.state.lastName === "" ||
      this.state.address === "" ||
      this.state.postcode === "" ||
      this.state.countryCode === "" ||
      this.state.phone === "" ||
      this.state.country === "" ||
      this.state.year === "year" ||
      this.state.month === "month" ||
      this.state.day === "day"
    ) {
      this.setState({
        valid: "All fields are required",
      });
    } else if (!emailPattern.test(this.state.email)) {
      this.setState({
        valid: "Please enter valid email address",
      });
    } else if (this.state.newPassword !== this.state.newPasswordConfirm) {
      this.setState({
        valid: "Password do not match",
      });
    } else if (
      this.state.newPassword &&
      this.state.newPasswordConfirm &&
      !passwordPattern.test(this.state.newPassword) &&
      !passwordPattern.test(this.state.newPasswordConfirm)
    ) {
      this.setState({
        valid:
          "Please enter a  password with at least eight characters, at least one letter, one number and one special character.",
      });
    } else if (!validAge) {
      this.setState({
        valid: "You don't have 18 years or more",
      });
    } else {
      this.setState({
        valid: "",
      });
      if (this.state.data) {
        const ext = this.state.photo.name.split(".").pop();
        if (
          this.state.photo.size < 6000000 &&
          (ext === "jpg" || ext === "png" || ext === "jpeg" || ext === "pdf")
        ) {
          const dataFetch = {
            method: "POST",
            headers: {},
            body: this.state.data,
          };
          const response = await fetch("/api/app/avupload", dataFetch);
          const data = await response.json();
          if (!data.ok) {
            this.setState({
              valid: data.error,
            });
          }
        }
      }
      const dataFetch = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          newPassword: this.state.newPassword
            ? this.state.newPassword
            : undefined,
          newPasswordConfirm: this.state.newPasswordConfirm
            ? this.state.newPasswordConfirm
            : undefined,
          address: this.state.address,
          postcode: this.state.postcode,
          countryCode: this.state.countryCode,
          phone: this.state.phone,
          country: this.state.country,
          DOB:
            "" +
            this.state.year +
            "/" +
            this.state.month +
            "/" +
            this.state.day,
          config: {
            jobsWithinRange: this.state.jobsWithinRange,
            lowerPaidJobs: this.state.lowerPaidJobs,
            training: this.state.training,
            surveysAndResearch: this.state.surveysAndResearch,
            remoteWorkTips: this.state.remoteWorkTips,
          },
        }),
      };
      const response = await fetch("/api/app/editUser", dataFetch);
      const data = await response.json();
      if (data.ok) {
        window.location.reload();
      } else {
        this.setState({ valid: data.error });
      }
    }
  };
  propsValidator = (props) => {
    if (!props.data.check2fa) {
      window.location = "/phonecheck";
    }
  };
  render() {
    if(Object.values(this.props.data).length > 0) {

      this.propsValidator(this.props);
    }
    return (
      <Fragment>
        <div className="container-login">
          <div className="container-blue" style={{ height: "100%" }}>
            <ProfileCard
              user={this.state}
              handleUpload={this.handleUpload}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              handleSelect={this.handleSelect}
            />
          </div>
          <div className="rounded-lg container-form">
            <Notifications handleChange={this.handleChange} user={this.state} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Profile;
