import React, { Component, Fragment } from "react";
import TopNav from "./navbar";
import Router from "../router";

class Layout extends Component {
  constructor() {
    super();
    this.state = {
      user: {},
    };
  }
  async componentDidMount() {
    const response = await fetch("/api/app/current-user");
    const data = await response.json();
    if (data.ok) {
      sessionStorage.setItem("logged", true);
      this.setState({
        user: data,
      });
    }
  }
  render() {
    return (
      <Fragment>
        <div id="navbar">
          <TopNav data={this.state.user} />
        </div>
        <main>
          <Router data={this.state.user} />
        </main>
      </Fragment>
    );
  }
}

export default Layout;
