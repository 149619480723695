import React, { Component, Fragment } from "react";
import { Navbar, Form, FormControl } from "react-bootstrap";
import ArrowDownLineIcon from "@rsuite/icons/ArrowDownLine";
import ArrowUpLineIcon from "@rsuite/icons/ArrowUpLine";
import "./navbar.css";

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed: false,
    };
  }
  handleClick = () => {
    this.setState({
      displayed: !this.state.displayed,
    });
  };
  handleLogout = async () => {
    const response = await fetch("/api/account/logout");
    const data = await response.json();
    if (data.ok) {
      sessionStorage.removeItem("logged");
      window.location = "/login";
    }
  };
  render() {
    return (
      <Navbar className="navbar-primary" expand="lg">
        <Navbar.Brand
          href="/"
          className={
            window.location.pathname.split("/")[1] === "dashboard"
              ? "navbarSecondaryLight section-logo"
              : "section-logo"
          }
        >
          <img
            src={`/assets/images/${
              window.location.pathname.split("/")[1] === "dashboard"
                ? "mta_blue.png"
                : "navbarlogo.png"
            }`}
            height="40"
            className="d-inline-block align-top"
            alt="My Team Abroad logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="section-search">
          <Form>
            <FormControl type="text" placeholder="Search" className="search" />
          </Form>
          {this.props.data.ok ? (
            <Fragment>
              <div onClick={this.handleClick} className="littlebar">
                <img
                  className="rounded-circle navImage"
                  src={
                    this.props.data.avatar
                      ? this.props.data.avatar
                      : "/assets/images/avplaceholder.png"
                  }
                  alt="User avatar"
                />
                <p>{this.props.data.name.split(" ")[0]}</p>
                {this.state.displayed ? (
                  <ArrowUpLineIcon />
                ) : (
                  <ArrowDownLineIcon />
                )}
              </div>
              {this.state.displayed ? (
                <div className="floatingSquare rounded-lg ">
                  <ul className="floatingList">
                    {this.props.data.check2fa && (
                      <>
                        <li className="rowList">
                          <a className="noColor" href="/profile">
                            <img
                              alt="profile icon"
                              className="listImage"
                              src="/assets/images/profile.png"
                            />
                            Profile
                          </a>
                        </li>
                        <li className="rowList">
                          <a className="noColor" href="/dashboard">
                            <img
                              alt="settings icon"
                              className="listImage"
                              src="/assets/images/settings.png"
                            />
                            Settings
                          </a>
                        </li>
                      </>
                    )}
                    <li
                      onClick={(e) => this.handleLogout()}
                      className="rowList"
                    >
                      <img
                        alt="logout icon"
                        className="listImage"
                        src="/assets/images/logout.png"
                      />
                      Logout
                    </li>
                  </ul>
                </div>
              ) : null}
            </Fragment>
          ) : null}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default TopNav;
