import React, { Component } from "react";
import SkillForm from "./SkillForm";
import MailSent from "./Mailskill";
import TimelineComponent from "../auth/components/Timeline";
import { timelines } from "../auth/utils";

class DreamJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      sent: false,
      skills: [],
      selectTimeline: 3,
    };
  }
  propsValidator = (props) => {
    if (!props.data.check2fa) {
      window.location = "/phonecheck";
    }
  };
  handleSend = (skills) => {
    this.setState({
      sent: true,
      skills,
    });
  };

  render(props) {
    if(Object.values(this.props.data).length > 0) {

      this.propsValidator(this.props);
    }
    return (
      <div className="container-login">
        <div className="container-blue" style={{ padding: "50px" }}>
          <TimelineComponent
            timelines={timelines}
            selectTimeline={this.state.selectTimeline}
          />
        </div>
        <div className="rounded-lg container-form">
          {this.state.sent ? (
            <MailSent skills={this.state.skills} name={this.props.data.name} />
          ) : (
            <SkillForm
              name={this.props.data.name}
              handleSend={this.handleSend}
              skills={this.state.skills}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DreamJob;
