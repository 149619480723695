import React, { Component } from "react";
import DreamJobDash from "./DreamjobDash";
import Stats from "./Stast";
import SkillsTable from "./SkillsTable";
import "./dashboard.css";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      english: {},
      skills: [],
      basicsPass: false,
      checkid: false,
    };
  }

  async componentDidMount() {
    try {
      let response = await fetch("/api/app/current-user/skills");
      let data = await response.json();
      if (data.error) {
      } else {
        const temp = data.skills.filter((elem) => {
          return elem.score === "Not taken" || elem.score === "Pending";
        }).length;
        if (data.skills.length === 0) {
          window.location = "/skills";
        }
        if (temp === 0 && !data.english) {
          window.location = "/english";
        } else if (!data.photo && temp === 0 && !data.english) {
          window.location = "/checkid";
        } else if (!data.check2fa) {
          window.location = "/phonecheck";
        } else {
          this.setState({
            english: data.english,
            skills: data.skills,
            subSkills: data.subSkills,
            basicsPass: data.basicsPass,
            checkid: temp === 0 && data.english,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="dashboard">
        <div className="dashRow">
          <DreamJobDash />
          {this.state.skills.length >= 2 && (
            <Stats skills={this.state.skills} />
          )}
        </div>
        {this.state.skills.length >= 2 && (
          <SkillsTable
            skills={this.state.skills}
            basicsPass={this.state.basicsPass}
            subSkills={this.state.subSkills}
            english={this.state.english}
          />
        )}
        {this.state.checkid ? (
          <a
            href="/checkid"
            className="btn btn-m buttonSecondaryDash paddingIdBtn buttomAdmin"
          >
            Check my ID
          </a>
        ) : null}
      </div>
    );
  }
}

export default Dashboard;
