import React, { Component } from "react";
import { Form } from "react-bootstrap";

class PasswordCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ["", "", "", "", "", "", ""],
    };
  }
  handleChange = (event, index) => {
    const { value } = event.target;
    const code = this.state.code;
    code[index] = value;
    this.setState({
      code,
    });
  };

  handleResend = async () => {
    await fetch("/api/phone/sendcode");
  };

  async componentDidMount() {
    const script = document.createElement("script");
    script.src = "/assets/js/phoneCheckHandler.js";
    script.async = true;
    document.body.appendChild(script);
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const code = this.state.code.join("");
    if (code.length < 7) {
      this.setState({
        valid: "Wrong code!",
      });
    } else {
      const dataFetch = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          code,
        }),
      };
      const response = await fetch("/api/phone/verifyCode", dataFetch);
      const data = await response.json();
      if (data.ok) {
        fetch("/api/mails/welcomeUser");
        window.location = "/dreamjob";
      } else {
        this.setState({
          valid: "Wrong code!",
        });
      }
    }
  };
  render() {
    return (
      <div className="cardContainerPass">
        <h2 className="title passTitle">AUTHY</h2>
        <p className="phoneIntro">
          {" "}
          Please confirm your phone number, you will receive a security code{" "}
        </p>
        <div className="phoneColumn">
          <p>Enter the code</p>
          <Form className="phoneForm" onSubmit={(e) => this.handleSubmit(e)}>
            <Form.Group className="formInput">
              <div className="phoneRow">
                {this.state.code.map((elem, index) => (
                  <Form.Control
                    type="text"
                    key={elem + " " + index}
                    id={`digit${index}`}
                    placeholder="-"
                    required
                    value={elem}
                    className="digit"
                    onChange={(e) => this.handleChange(e, index)}
                  />
                ))}
              </div>
            </Form.Group>
            {this.state.valid ? (
              <p className="warning">{this.state.valid}</p>
            ) : null}
            <button className="col-5 btn btn-m buttonSecondary">Confirm</button>
          </Form>
        </div>
        <p>
          Problems with phone verification?
          <a
            href="mailto:support@myteamabroad.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Contact us
          </a>{" "}
          or
          <span
            className="resendPhone"
            onClick={(e) => {
              this.handleResend();
            }}
          >
            {" "}
            Re-send code
          </span>
        </p>
      </div>
    );
  }
}

export default PasswordCard;
