import React, { Component, Fragment } from "react";
import "./english.css";

class EnglishCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: "",
    };
  }
  handleClick = async (e) => {
    e.preventDefault();
    const response = await fetch("/api/app/createEnglish");
    const data = await response.json();
    if (data.ok) {
      this.props.manageClick();
    }
  };
  render(props) {
    return (
      <Fragment>
        <div className="cardEnglish">
          <h2 className="titleRegister">ENGLISH</h2>
          <p>Hello {this.props.name}</p>
          <p className="bottomText textchekid marginEnglishText">
            Do you want to take the test in English to increase your chances of
            getting opportunities by more than a 80%
          </p>
          <img
            className="englishImage"
            alt="Woman taking test"
            src="/assets/images/english1.png"
          />
          {this.state.valid ? (
            <p className="warning">{this.state.valid}</p>
          ) : null}
          <div className="buttomsRow">
            <button
              className="btn btn-m buttomPrimaryEng paddingIdBtn"
              onClick={(e) => this.handleClick(e)}
            >
              Take English Test
            </button>
            {/* <p>or</p>
            <button
              onClick={(e) => this.props.handleReady(e)}
              className="btn btn-m buttonSecondaryEng paddingIdBtn"
            >
              Ready to be hired
            </button> */}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EnglishCard;
