import React, { Component } from "react";
import moment from "moment";

class SkillsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: [],
      rerender: false,
    };
  }

  formatProps = async (element) => {
    const response = await fetch("/api/app/canPresent/" + element._id);
    const data = await response.json();
    const testTime = new Date(element.testDate);
    const year = testTime.getFullYear();
    const month = testTime.getUTCMonth();
    const day = testTime.getUTCDate();
    let timeNextTest = new Date(
      year.toString() + "-" + (month + 1) + "-" + day
    );
    element.time = data.time;
    timeNextTest.setDate(day + data.time);
    element.testDate = this.dateOfBirthFormat(element.testDate);
    if (element.skill !== "MTA - Logic Exam") {
      element.testPresent =
        element.score < 50 ? this.dateOfBirthFormat(timeNextTest) : false;
    } else {
      element.testPresent =
        element.score < 90 ? this.dateOfBirthFormat(timeNextTest) : false;
    }
    return element;
  };

  render(props) {
    return (
      <table className="dashTable">
        <thead>
          <tr>
            <th className="headerTableDash cellTableDash">Seniority</th>
            <th className="headerTableDash cellTableDash">Skill</th>
            <th className="headerTableDash cellTableDash">Taken</th>
            <th className="headerTableDash cellTableDash">Score</th>
            <th className="headerTableDash cellTableDash">Pass/Fail</th>
            <th className="headerTableDash cellTableDash">ReTake</th>
          </tr>
        </thead>
        <tbody>
          {this.props.skills.map((skill, index) => {
            const redirectSkill = () => {
              window.location = `/resendskill/${skill._id}`;
            };
            console.log(skill);
            return (
              <tr key={skill._id}>
                <td className="cellTableDash">{skill.seniority}</td>
                <td className="cellTableDash">
                  <div className="cellWithImage">
                    <div className="placeholder"> </div>
                    {skill.skill}
                    {this.props.subSkills[index].time <= 0 &&
                    skill.skill !== "MTA - Logic Exam" &&
                    skill.skill !== "English" ? (
                      <a
                        className="skillImage"
                        href={`/edit-skill/${skill._id}`}
                      >
                        <img
                          className="rounded-circle littleImage"
                          alt="edit icon"
                          src="/assets/images/edit.png"
                        />
                      </a>
                    ) : (
                      <div className="placeholder"> </div>
                    )}{" "}
                  </div>
                </td>
                <td className="cellTableDash">
                  {this.props.subSkills[index].testDateFormat}
                </td>
                <td className="cellTableDash">
                  {skill.score ? skill.score : " - "}
                </td>
                <td className="cellTableDash">
                  {skill.score !== "Not taken" && skill.score !== "Pending"
                    ? skill.score >= 50 && skill.skill !== "MTA - Logic Exam"
                      ? "Pass"
                      : skill.skill === "MTA - Logic Exam" && skill.score > 90
                      ? "Pass"
                      : "Fail"
                    : " - "}
                </td>
                <td className="cellTableDash">
                  {(skill.skill === "MTA - Logic Exam" &&
                    parseInt(skill.score) > 90) ||
                  (parseInt(skill.score) >= 50 &&
                    skill.skill !== "MTA - Logic Exam") ||
                  (skill.skill !== "MTA - Logic Exam" &&
                    !this.props.basicsPass) ? (
                    `Option not avaliable`
                  ) : this.props.subSkills[index].time <= 0 ||
                    skill.score === "Pending" ? (
                    <button
                      className="btn btn-m buttonSecondaryDash paddingIdBtn buttomAdmin"
                      onClick={redirectSkill}
                      disabled={parseInt(skill.score) < 90 && skill.days > 0 ? true : false}
                    >
                      {skill.score === "Not taken"
                        ? "Take"
                        : skill.days > 0
                        ? `You can retake test in ${skill.days} ${
                            skill.days > 1 ? "days" : "day"
                          }`
                        : "ReTake"}
                    </button>
                  ) : (
                    `Option avaliable on ${this.props.subSkills[index].timeNextTest}`
                  )}
                </td>
              </tr>
            );
          })}

          <tr>
            <td className="cellTableDash"> - </td>
            <td className="cellTableDash">
              <div className="cellWithImage">
                <div className="placeholder"> </div>
                English
                <div className="placeholder"> </div>
              </div>
            </td>
            <td className="cellTableDash">
              {this.props.english ? this.props.english.testDate : "-"}
            </td>
            <td className="cellTableDash">
              {" "}
              {this.props.english ? this.props.english.score : "-"}{" "}
            </td>
            <td className="cellTableDash">
              {" "}
              {this.props.english ? this.props.english.proficiency : "-"}{" "}
            </td>
            <td className="cellTableDash">
              {!this.props.english ||
              new RegExp("Not wanted").test(
                this.props.english.link.split("/").pop()
              ) ||
              new RegExp("waiting").test(
                this.props.english.link.split("/").pop()
              ) ? (
                <a
                  className="btn btn-m buttonSecondaryDash paddingIdBtn buttomAdmin"
                  href={`/english`}
                >
                  Take
                </a>
              ) : new RegExp("incorrect").test(
                  this.props.english.link.split("/").pop()
                ) ? (
                <a
                  className="btn btn-m buttonSecondaryDash paddingIdBtn buttomAdmin"
                  href={`/english`}
                >
                  Correct link
                </a>
              ) : (
                "Test already taken"
              )}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default SkillsTable;
