import { CircularProgress } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";

class EnglishMailSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: "",
      url: "",
      loading: false,
    };
  }
  handleResend = async () => {
    await fetch("/api/mails/secondstep");
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.url.split("/")[2]?.split(".")[1] !== "efset") {
      this.setState({
        valid: "Enter a link about the valid english exam",
      });
    } else {
      this.setState({
        loading: true,
      });
      if (this.state.url !== "") {
        const dataFetch = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            url: this.state.url,
            username: this.props.data.name
          }),
        };
        const response = await fetch("/api/app/registerEnglish", dataFetch);
        const data = await response.json();
        data.ok
          ? (window.location = "/skills")
          : this.setState({
              valid: data.error ? data.error : "There was an error, try again",
            });

        this.setState({
          loading: false,
        });
      } else {
        this.setState({
          valid: "Certification link is missing",
        });
      }
    }
  };

  render() {
    return (
      <Fragment>
        <div className="cardEnglish">
          <h2 className="titleRegister">ENGLISH</h2>
          <p>Hello {this.props.name}</p>
          <p className="bottomText textchekid marginEnglishText">
            You chose to take the exam in English to increase your chances of
            getting opportunities by more than 80%. We sent you the test link
            through email
          </p>
          <img
            className="englishImage"
            alt="Woman taking test"
            src="/assets/images/mailsend.png"
          />
          <Form
            className="formJob englishWidth"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <Form.Group className="formInput">
              <div className="contentUrlCert PairMakerJob">
                <p className="labelJob">Cert url: </p>
                <Form.Control
                  className=""
                  type="text"
                  id="url"
                  name="url"
                  placeholder="url"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.url}
                />
              </div>
            </Form.Group>
            {this.state.valid ? (
              <p className="warning">{this.state.valid}</p>
            ) : null}
            <div className="buttomsRow">
              <button
                className="btn btn-m buttomPrimaryEng paddingIdBtn"
                onClick={(e) => {
                  this.handleResend();
                }}
                disabled={this.state.loading}
              >
                Resend email with Email Test
              </button>
              <p>or</p>
              <button
                disabled={this.state.loading}
                className="btn btn-m buttonSecondaryEng paddingIdBtn"
              >
                Submit
                {this.state.loading && (
                  <CircularProgress
                    size={16}
                    color="#fff"
                    style={{ marginLeft: "10px" }}
                  />
                )}
              </button>
            </div>
          </Form>
        </div>
      </Fragment>
    );
  }
}

export default EnglishMailSend;
