import React, { Component } from "react";
class Timeline extends Component {
  componentDidMount() {
    //const script = document.createElement("script");
    //script.src = "/assets/js/positionScript2.js";
    //script.async = true;
    //document.body.appendChild(script);
  }

  render() {
    return (
      <div className="containerTime">
        <ul className="progressbar">
          <li className="active manager">Sign up</li>
          <li className="manager active">Your dream job</li>
          <li className="manager active">Skills</li>
          <li className="manager active">English</li>
          <li className="manager active">CV Load</li>
          <li id="active" className="active manager">
            ID check
          </li>
          <li className="manager">Ready to be hired</li>
        </ul>
        <div id="stepMarker" className="stepMarker checkidTL">
          You are here
        </div>
      </div>
    );
  }
}
export default Timeline;
