import React from 'react'
import RegisterCard from "./components/RegisterCard";
import Skills from "./components/RegisterForms/Skills";
import MtaResume from "./components/RegisterForms/MtaResume";
import CheckId from "./components/RegisterForms/CheckId";
import ReadyToBeHired from "./components/RegisterForms/ReadyToBeHired";
import JobForm from '../dreamjob/JobCard';
import English from '../english/English';

export const timelines = [
  {
    id: 0,
    title: "Sign Up",
    description:
      "We will requesting a form of ID later on, please use your name as it appears on.",
    content: <RegisterCard />,
  },
  {
    id: 1,
    title: "Dream Job",
    description: "It is now time to tell us what your dream job looks like.",
    content: <JobForm />,
  },
  {
    id: 2,
    title: "English Skill",
    description: "It is time to take an English test to advance in the registration",
    content: <English />,
  },
  {
    id: 3,
    title: "Technical Skills",
    description: "It is now time to tell us what you are good at it.",
    content: <Skills />,
  },
  {
    id: 4,
    title: "MTA CV",
    description: "It is now time to tell us what experience do you have.",
    content: <MtaResume />,
  },
  {
    id: 5,
    title: "Id Check",
    description: "",
    content: <CheckId />,
  },
  {
    id: 6,
    title: "Ready To Be Hired",
    description: "",
    content: <ReadyToBeHired />,
  },
];
