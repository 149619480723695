import React, { Fragment, useEffect } from "react";
import CheckCard from "./checkidCard";
import Timeline from "./timelinecheckId";
import "./checkid.css";

const propsValidator = (check2fa) => {
  if (!check2fa) {
    window.location = "/phonecheck";
  }
};

const Checkid = ({data}) => {
  useEffect(() => {
    if(Object.values(data).length === 0) return;
    propsValidator(data.check2fa);

    //eslint-disable-next-line
  }, [data.check2fa]);
  return (
    <div className="container-login">
      <div className="container-blue" style={{ padding: "50px" }}>
        <img
          className="imageAuth"
          src="/assets/images/idcheck.png"
          alt="Woman looking for a team"
        />
      </div>
      <div className="rounded-lg container-form">
        <CheckCard name={data.name} />
      </div>
    </div>
  );
};

export default Checkid;
