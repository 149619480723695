import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";

class JobForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      low: "",
      high: "",
      position: "",
      modality: "",
      valid: "",
      lowerPaidJobs: false,
      update: false,
    };
  }

  async componentDidMount() {
    const response = await fetch("/api/app/current-user/job");
    const data = await response.json();
    if (data.job) {
      this.setState({
        low: parseInt(data.job.salaryLow, 10),
        high: parseInt(data.job.salaryHigh, 10),
        position: data.job.position,
        modality: data.job.modality,
        lowerPaidJobs: data.job.lowerPaidJobs,
        update: true,
      });
    } else if (sessionStorage.getItem("jobData")) {
      const jobData = JSON.parse(sessionStorage.getItem("jobData"));
      this.setState({
        low: jobData.low,
        high: jobData.high,
        position: jobData.position,
        modality: jobData.modality,
        lowerPaidJobs: jobData.lowerPaidJobs,
      });
    }
  }

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    type === "checkbox"
      ? this.setState({
          [name]: checked,
        })
      : this.setState({
          [name]: value,
        });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !this.state.low ||
      !this.state.high ||
      !this.state.position ||
      !this.state.modality
    ) {
      this.setState({
        valid: "All fields are require!",
      });
    } else {
      const dataFetch = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          salaryLow: this.state.low,
          salaryHigh: this.state.high,
          position: this.state.position,
          modality: this.state.modality,
          lowerPaidJobs: this.state.lowerPaidJobs,
        }),
      };
      const response = await fetch("/api/app/registerJob", dataFetch);
      const data = await response.json();
      if (data.ok) {
        sessionStorage.setItem(
          "jobData",
          `{"low": "${this.state.low}", "high": "${this.state.high}", "position": "${this.state.position}", "modality": "${this.state.modality}"}`
        );
        window.location = this.state.update ? "/dashboard" : "/english";
      } else {
        this.setState({
          valid: data.error,
        });
      }
    }
  };
  render(props) {
    return (
      <Fragment>
        <div className="cardJob">
          <h2 className="titleRegister">YOUR DREAM JOB</h2>
          <p>Hello {this.props.name}</p>
          <p className="bottomText textchekid">
            It is now time to tell us what your dream job looks like
          </p>
          <Form className="formJob" onSubmit={(e) => this.handleSubmit(e)}>
            <Form.Group className="formInput">
              <div className="dreamSalary">
                <p className="labelJob">Desire monthly salary range</p>
                <Form.Control
                  className="widthDelJob"
                  type="number"
                  id="low"
                  name="low"
                  placeholder="USD"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.low}
                />

                <Form.Control
                  className="widthDelJob"
                  type="number"
                  id="high"
                  name="high"
                  placeholder="USD"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.high}
                />
                <div id="salary">
                  <img
                    className="imgJob"
                    alt="question mark"
                    src="/assets/images/info.png"
                  />
                </div>
              </div>
            </Form.Group>
            {this.state.update ? null : (
              <Form.Group className="formInput">
                <div className="PairMakerJob">
                  <label>Position</label>
                  <select
                    id="position"
                    name="position"
                    className="form-control selectJob marginLeft"
                    value={this.state.position}
                    onChange={(e) => this.handleChange(e)}
                  >
                    <option value="">-Select your positition-</option>
                    <option value="Full Stack Developer">
                      Full Stack Developer
                    </option>
                    <option value="Backend Developer">Backend Developer</option>
                    <option value="Frontend Developer">
                      Frontend Developer
                    </option>
                    <option value="Data engineer">Data engineer</option>
                    <option value="Data scientist">Data scientist</option>
                  </select>
                  <div className="rowJobs">
                    <div id="position">
                      <img
                        className="imgJob"
                        alt="question mark"
                        src="/assets/images/info.png"
                      />
                    </div>
                  </div>
                </div>
              </Form.Group>
            )}
            <Form.Group className="formInput">
              <div className="PairMakerJobSelect">
                <label>Part/Full time</label>
                <select
                  id="modality"
                  name="modality"
                  className="form-control selectJob marginLeft"
                  value={this.state.modality}
                  onChange={(e) => this.handleChange(e)}
                >
                  <option value="">-Select your modality-</option>
                  <option value="Full time">Full time</option>
                  <option value="Part time">Part time</option>
                </select>
                <div className="rowJobs">
                  <div id="modality">
                    <img
                      className="imgJob"
                      alt="question mark"
                      src="/assets/images/info.png"
                    />
                  </div>
                </div>
              </div>
            </Form.Group>
            <span className="rowCustom">
              <Checkbox
                checked={this.state.lowerPaidJobs}
                color="primary"
                id="lowerPaidJobs"
                inputProps={{
                  id: "lowerPaidJobs",
                  onChange: (e) => this.handleChange(e),
                  name: "lowerPaidJobs",
                }}
              />
              <label>
                I will like to receive jobs offers outside my desired salary
                range that match my skills.
              </label>
            </span>
            {this.state.valid ? (
              <p className="warning">{this.state.valid}</p>
            ) : null}
            <div>
              {this.state.update ? (
                <button
                  className="btn btn-m adminSecondButton paddingIdBtn buttomAdmin"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location = "/dashboard";
                  }}
                >
                  Go back
                </button>
              ) : null}
              <button className="btn btn-m buttonSecondary paddingIdBtn">
                {this.state.update ? "Save" : "Next"}
              </button>
            </div>
          </Form>
        </div>
      </Fragment>
    );
  }
}

export default JobForm;
