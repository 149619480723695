import React, { Component } from "react";
import { Form } from "react-bootstrap";

class Notifications extends Component {
	render() {
		return (
			<div className="cardContainerNotifications">
				<h3 className="titleNotification">
					Notifications
				</h3>
				<Form>
					<div className="rowNot">
						<div className="toggles">
							<span>
								<input
									type="checkbox"
									name="newsletter"
									id="newsletter"
									className="ios-toggle checkboxGreen"
									checked={this.props.user.newsletter}
									onChange={(e) => this.props.handleChange(e)}
								/>
								<label
									htmlFor="newsletter"
									className="checkbox-label checkboxGreen"
									data-off="off"
									data-on="on"
								></label>
							</span>
						</div>
						<p className="notTag">Newsletter</p>
					</div>
					<div className="rowNot">
						<div className="toggles">
							<span>
								<input
									type="checkbox"
									name="jobsWithinRange"
									id="jobsWithinRange"
									className="ios-toggle checkboxGreen"
									checked={this.props.user.jobsWithinRange}
									onChange={(e) => this.props.handleChange(e)}
								/>
								<label
									htmlFor="jobsWithinRange"
									className="checkbox-label checkboxGreen"
									data-off="off"
									data-on="on"
								></label>
							</span>
						</div>
						<p className="notTag">
							Job Opportunities within my salary range
						</p>
					</div>
					<div className="rowNot">
						<div className="toggles">
							<span>
								<input
									type="checkbox"
									name="lowerPaidJobs"
									id="lowerPaidJobs"
									className="ios-toggle checkboxGreen"
									checked={this.props.user.lowerPaidJobs}
									onChange={(e) => this.props.handleChange(e)}
								/>
								<label
									htmlFor="lowerPaidJobs"
									className="checkbox-label checkboxGreen"
									data-off="off"
									data-on="on"
								></label>
							</span>
						</div>
						<p className="notTag">
							Job Opportunities outside my salary range
						</p>
					</div>
					<div className="rowNot">
						<div className="toggles">
							<span>
								<input
									type="checkbox"
									name="training"
									id="training"
									className="ios-toggle checkboxGreen"
									checked={this.props.user.training}
									onChange={(e) => this.props.handleChange(e)}
								/>
								<label
									htmlFor="training"
									className="checkbox-label checkboxGreen"
									data-off="off"
									data-on="on"
								></label>
							</span>
						</div>
						<p className="notTag">Training</p>
					</div>
					<div className="rowNot">
						<div className="toggles">
							<span>
								<input
									type="checkbox"
									name="surveysAndResearch"
									id="surveysAndResearch"
									className="ios-toggle checkboxGreen"
									checked={this.props.user.surveysAndResearch}
									onChange={(e) => this.props.handleChange(e)}
								/>
								<label
									htmlFor="surveysAndResearch"
									className="checkbox-label checkboxGreen"
									data-off="off"
									data-on="on"
								></label>
							</span>
						</div>
						<p className="notTag">Surveys and market research</p>
					</div>
					<div className="rowNot">
						<div className="toggles">
							<span>
								<input
									type="checkbox"
									name="remoteWorkTips"
									id="remoteWorkTips"
									className="ios-toggle checkboxGreen"
									checked={this.props.user.remoteWorkTips}
									onChange={(e) => this.props.handleChange(e)}
								/>
								<label
									htmlFor="remoteWorkTips"
									className="checkbox-label checkboxGreen"
									data-off="off"
									data-on="on"
								></label>
							</span>
						</div>
						<p className="notTag">Remote work tips</p>
					</div>
				</Form>
			</div>
		);
	}
}

export default Notifications;
