import React, { Component } from "react";
import LoginCard from "./components/LoginCard";
import "./auth.css";

class Login extends Component {
  render() {
    return (
      <div className="container-login">
        <div className="container-blue">
          <div className="content-title">
            <h1>WELCOME TO</h1>
            <h1>MY TEAM ABROAD</h1>
          </div>
        </div>
        <div className="container-form">
          <div>
            <LoginCard />
          </div>
        </div>
      </div>
    );
  }
}

export default Login;

/*
 
*/
