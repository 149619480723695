import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class CreatePartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      users: [],
      passwordEdit: "",
      passwordEditInput: "",
    };
  }
  async componentDidMount() {
    const user = JSON.parse(window.localStorage.getItem("user"));
    if (user) {
      this.setState({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: user.password,
      });
    }
    const response = await fetch("/admin/allPartners");
    const data = await response.json();
    this.setState({
      users: [...this.state.users, ...data.partners],
    });
    console.log(this.state.users);
  }
  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    type === "checkbox"
      ? this.setState({
          [name]: checked,
        })
      : this.setState({
          [name]: value,
        });
  };

  handleAlphabeticSort = (event, key) => {
    if (/^skill/.test(key)) {
      this.setState({
        users: this.state.users.sort((a, b) => {
          if (a[key].skill.toLowerCase() < b[key].skill.toLowerCase()) {
            return -1;
          }
          if (a[key].skill.toLowerCase() < b[key].skill.toLowerCase()) {
            return 1;
          }
          return 0;
        }),
      });
    } else {
      this.setState({
        users: this.state.users.sort((a, b) => {
          if (a[key].toLowerCase() < b[key].toLowerCase()) {
            return -1;
          }
          if (a[key].toLowerCase() < b[key].toLowerCase()) {
            return 1;
          }
          return 0;
        }),
      });
    }
  };

  handleDelete = async (event, email) => {
    const response = await fetch(`/admin/deletePartner/${email}`);
    const data = await response.json();
    if (data.ok) {
      this.setState({
        users: this.state.users.filter((user) => user.email !== email),
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const emailPattern = new RegExp(
      // eslint-disable-next-line
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );

    const passwordPattern = new RegExp(
      // eslint-disable-next-line
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    );
    if (
      this.state.email === "" ||
      this.state.password === "" ||
      this.state.firstName === "" ||
      this.state.lastName === ""
    ) {
      toast.error("All fields are required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!emailPattern.test(this.state.email)) {
      console.log("hola3");
      toast.error("Insert a valid email", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!passwordPattern.test(this.state.password)) {
      console.log("hola2");
      toast.error(
        "Please enter a  password with at least eight characters, at least one letter, one number and one special character.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      console.log("hola");
      const dataFetch = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email.toLowerCase(),
          password: this.state.password,
        }),
      };
      const response = await fetch("/admin/createpartner", dataFetch);
      const data = await response.json();
      if (!data.ok) {
        toast.error(data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Partner created successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  handlePassClick = (e, email) => {
    this.setState({
      passwordEdit: email,
    });
  };
  handlePassSend = async (e) => {
    const dataFetch = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.passwordEdit,
        password: this.state.passwordEditInput,
      }),
    };
    const response = await fetch("/admin/changepasswordpartner", dataFetch);
    const data = await response.json();
    if (!data.ok) {
      toast.error(data.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success("Password changed successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    window.location = "/admin/partner";
  };
  handleInput = (event) => {
    this.setState({
      passwordEditInput: event.target.value,
    });
  };

  render(props) {
    return (
      <Fragment>
        <div className="cardContainer  bg-light rounded-lg">
          <h2 className="title">MANAGE PARTNERS</h2>

          <Form onSubmit={(e) => this.handleSubmit(e)} className="authForm">
            <Form.Group className="formInput">
              <div className="PairMaker">
                <Form.Control
                  className="widthDel"
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.firstName}
                />

                <Form.Control
                  className="widthDel"
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.lastName}
                />
              </div>
            </Form.Group>

            <Form.Group className="formInput">
              <div className="PairMaker">
                <Form.Control
                  className="widthDel"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.email}
                />

                <Form.Control
                  className="widthDel"
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.password}
                />
              </div>
            </Form.Group>

            {
              //Linea de separacion, todo deberia estar por encima de esto
            }
            <div className="PairMaker" style={{ flexWrap: "nowrap" }}>
              <button
                className="col-5 btn btn-m buttonSecondary"
                onClick={(e) => {
                  this.handleSubmit(e);
                }}
              >
                Create Partner
              </button>
              <button
                className="col-5 btn btn-m adminSecondButton paddingIdBtn buttomAdmin"
                onClick={(e) => {
                  e.preventDefault();
                  window.location = "/admin";
                }}
              >
                Go Back
              </button>
            </div>
          </Form>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <table className="adminTablePartners  bg-light ">
          <thead>
            <tr>
              <th className="invisiCell"></th>
              <th className="headerTable cellTable">
                Name
                <span
                  onClick={(e) => {
                    this.handleAlphabeticSort(e, "firstName");
                  }}
                >
                  &#9660;
                </span>
              </th>
              <th className="headerTable cellTable">
                Last Name
                <span
                  onClick={(e) => {
                    this.handleAlphabeticSort(e, "lastName");
                  }}
                >
                  &#9660;
                </span>
              </th>
              <th className="headerTable cellTable">
                Email{" "}
                <span
                  onClick={(e) => {
                    this.handleAlphabeticSort(e, "email");
                  }}
                >
                  &#9660;
                </span>
              </th>
              <th className="headerTable cellTable">Password</th>
            </tr>
          </thead>
          <tbody>
            {this.state.users.map((user) => (
              <tr key={user.email}>
                <td>
                  {" "}
                  <button
                    onClick={(e) => this.handleDelete(e, user.email)}
                    className="deleteIcon"
                  >
                    &#9747;
                  </button>
                </td>
                <td className="cellTable">{user.firstName}</td>
                <td className="cellTable">{user.lastName}</td>
                <td className="cellTable">{user.email}</td>
                <td className="cellTable">
                  {this.state.passwordEdit === user.email ? (
                    <div>
                      <input
                        type="password"
                        name="passwordEditInput"
                        value={this.state.passwordEditInput}
                        onChange={(e) => this.handleInput(e)}
                      ></input>
                      <button
                        onClick={(e) =>
                          this.handlePassSend(e, user, user.email)
                        }
                      >
                        Send
                      </button>
                    </div>
                  ) : (
                    <Fragment>
                      {user.password} {"    "}
                      <img
                        className="rounded-circle smallImageAdmin"
                        alt="edit icon"
                        src="/assets/images/edit.png"
                        onClick={(e) => this.handlePassClick(e, user.email)}
                      />
                    </Fragment>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

export default CreatePartner;
