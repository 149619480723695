import React, { Component, Fragment } from "react";

class SendTestCard extends Component {

  handleClick = async (event, id) => {
    event.preventDefault();
    const response = await fetch(`/api/mails/sendSkills/${id}`);
    const data = await response.json();
    if (data.ok) {
      window.location.reload();
    } else {
      console.log("hola");
      window.location = `/resendskill/${data.skill._id}`;
    }
  };

  render(props) {
    return (
      <Fragment>
        <div className="cardJob">
          <h2 className="titleRegister">SKILLS</h2>
          <p>Hello {this.props.name}</p>
          <p className="bottomText textchekid marginEnglishText">
            You will receive a {this.props.skill} test you can present your
            tests for other skills once you finish this one
          </p>
          <img
            className="englishImage"
            alt="Woman taking test"
            src="/assets/images/mailsend.png"
          />
          <div className="buttomsRow">
            <button
              className="btn btn-m buttonSecondaryEng paddingIdBtn"
              onClick={(e) => {
                this.handleClick(e, this.props.id);
              }}
            >
              Take Test
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SendTestCard;
