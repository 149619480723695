import React, { Fragment, useEffect } from "react";
import TimelineSkills from "./TimelineSkills";
import EditSkillForm from "./EditSkillForm";

const propsValidator = (check2fa) => {
  if (!check2fa) {
    window.location = "/dashboard";
  }
};

const EditSkill = ({data}) => {
	useEffect(() => {
    if(Object.values(data).length === 0) return;
    propsValidator(data.check2fa);

    //eslint-disable-next-line
  }, [data.check2fa]);
  return (
    <Fragment>
      <div className="columnJob">
        {/* <TimelineSkills /> */}
        <div className="whitebg skillsBg">
          <EditSkillForm name={data.name} />
        </div>
      </div>
    </Fragment>
  );
};

export default EditSkill;
