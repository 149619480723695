import React, { Fragment } from "react";
import "./password.css";
import PasswordCard from "./FPasswordCard";

function FPassword() {
  return (
    <div className="container-login">
      <div className="container-blue" style={{ padding: "10px" }}>
        <img
          className="imageAuthLogin"
          src="/assets/images/password.png"
          alt="Woman defending her computer"
        />
      </div>
      <div className="rounded-lg container-form">
        <PasswordCard />
      </div>
    </div>
  );
}

export default FPassword;
