import React from "react";
import "./timeline.css";
import { Timeline } from "rsuite";
import styled from "styled-components";

const ContainerItem = styled(Timeline.Item)`
  .rs-timeline-item-content {
    padding-bottom: 0px !important;
  }

  .rs-timeline-item-dot::before {
    background-color: ${(props) =>
      props.bg
        ? `${props.bg} !important`
        : "rgba(255,255,255, 0.2) !important"};
    border: 1px rgba(255, 255, 255, 0.2) solid;
  }

  @media only screen and (max-width: 827px) {
    display: none;
  }
`;

const TimelineComponent = ({ timelines, selectTimeline }) => {
  return (
    <Timeline>
      {timelines.map((timeline) => (
        <ContainerItem
          key={timeline.id}
          bg={
            selectTimeline === timeline.id ? "#fff" : "rgba(255,255,255, 0.2)"
          }
        >
          <h1
            className={
              selectTimeline === timeline.id
                ? "title-active item"
                : "title-desactive item"
            }
          >
            {timeline.title}
          </h1>
          {selectTimeline === timeline.id && (
            <p className="p">{timeline.description}</p>
          )}
        </ContainerItem>
      ))}
    </Timeline>
  );
};

export default TimelineComponent;
