import React, { Component, Fragment } from "react";
import { Form, Row } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";

class CVForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      experience: [
        {
          title: "",
          task: ["", "", ""],
          place: "",
          dateStart: "",
          dateFinish: "",
        },
      ],
      education: [
        {
          title: "",
          place: "",
          dateStart: "",
          dateFinish: "",
        },
      ],
    };
  }

  async componentDidMount() {
    const response = await fetch("/api/app/readCV");
    const data = await response.json();

    if (data.cv) {
      data.cv.experience.forEach((elem) => {
        if (elem.task.length < 3) elem.task.push("");
      });
      this.setState({
        description: data.cv.description,
        experience: data.cv.experience,
        education: data.cv.education,
      });
    }
  }

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    type === "checkbox"
      ? this.setState({
          [name]: checked,
        })
      : this.setState({
          [name]: value,
        });
  };

  handleChangeExpTitle = (event) => {
    const { name, value, type, checked } = event.target;
    const i = name.split("-")[1];
    const changed = name.split("-")[0];
    const elem = this.state.experience[i];
    elem[changed] = value;
    this.setState((prevState) => {
      const newState = prevState.experience.slice(0);
      newState.splice(i, 1, elem);
      return {
        experience: newState,
      };
    });
  };

  handleChangeEdu = (event) => {
    const { name, value, type, checked } = event.target;
    const i = name.split("-")[1];
    const changed = name.split("-")[0];
    const elem = this.state.education[i];
    elem[changed] = value;
    this.setState((prevState) => {
      const newState = prevState.education.slice(0);
      newState.splice(i, 1, elem);
      return {
        education: newState,
      };
    });
  };

  handleChangeExpTasks = (event) => {
    const { name, value, type, checked } = event.target;
    const i = name.split("-")[1];
    const j = name.split("-")[2];
    const changed = name.split("-")[0];
    this.state.experience[i].task[j] = value;
    const elem = this.state.experience[i];
    this.setState((prevState) => {
      const newState = prevState.experience.slice(0);
      newState.splice(i, 1, elem);
      return {
        experience: newState,
      };
    });
  };
  handlePlus = () => {
    this.setState((prevState) => {
      return {
        experience: [
          ...prevState.experience,
          {
            title: "",
            task: ["", "", ""],
            place: "",
            dateStart: "",
            dateFinish: "",
          },
        ],
      };
    });
  };
  handlePlusEdu = () => {
    this.setState((prevState) => {
      return {
        education: [
          ...prevState.education,
          {
            title: "",
            place: "",
            dateStart: "",
            dateFinish: "",
          },
        ],
      };
    });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !this.state.education.length ||
      !this.state.experience.length ||
      !this.state.description
    ) {
      this.setState({
        valid: "All fields are require!",
      });
    } else {
      const dataFetch = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          education: this.state.education,
          experience: this.state.experience,
          description: this.state.description,
        }),
      };
      const response = await fetch("/api/app/registerCV", dataFetch);
      const data = await response.json();
      if (data.ok) {
        window.location = "/checkid";
      } else {
        this.setState({
          valid: data.error,
        });
      }
    }
  };
  render(props) {
    //console.log("-----------------", this.state);
    return (
      <div className="containerForm">
        <div className="cardJob">
          <h2 className="titleRegister">YOUR MTA RESUME</h2>
          <p>Hello {this.props.name}</p>
          <p className="bottomText textchekid">
            It is now time to tell us what experience do you have
          </p>
          <Form className="formJob" onSubmit={(e) => this.handleSubmit(e)}>
            <Form.Group className="formInput">
              <div className="PairMakerJob">
                <Form.Label className="labelJob">Description:</Form.Label>
                <Form.Control
                  className="descriptionCV"
                  id="description"
                  name="description"
                  as="textarea"
                  value={this.state.description}
                  onChange={(e) => this.handleChange(e)}
                />
                <div id="descriptionIcon">
                  <img
                    className="imgJob"
                    alt="question mark"
                    src="/assets/images/info.png"
                  />
                </div>
              </div>
            </Form.Group>
            <div className="PairMakerJob">
              <label>Experience: </label>
              <div className="rowJobs">
                <div id="experienceCV">
                  <img
                    className="imgJob"
                    alt="question mark"
                    src="/assets/images/info.png"
                  />
                </div>
              </div>
            </div>
            {this.state.experience.map((elem, i) => {
              return (
                <Form.Group key={i} className="formInput">
                  <div className="PairMakerJob">
                    <div className="rowCV">
                      <Form.Label className="titleLabelCV">Title </Form.Label>
                      <Form.Control
                        id={`title${i}`}
                        name={`title-${i}`}
                        type="text"
                        className="form-control taskCV marginLeft"
                        value={this.state.experience[i].title}
                        onChange={(e) => this.handleChangeExpTitle(e)}
                      />
                    </div>
                    <div className="rowCV">
                      <Form.Label className="titleLabelCV">Task 1 </Form.Label>
                      <Form.Control
                        id={`task1${i}`}
                        name={`task1-${i}-0`}
                        type="text"
                        className="form-control taskCV marginLeft"
                        value={this.state.experience[i].task[0]}
                        onChange={(e) => this.handleChangeExpTasks(e)}
                      />
                    </div>
                  </div>
                  <div className="PairMakerJob">
                    <div className="rowCV">
                      <Form.Label className="titleLabelCV">Task 2 </Form.Label>
                      <Form.Control
                        id={`task2${i}`}
                        name={`task2-${i}-1`}
                        type="text"
                        className="form-control taskCV marginLeft"
                        value={this.state.experience[i].task[1]}
                        onChange={(e) => this.handleChangeExpTasks(e)}
                      />
                    </div>
                    <div className="rowCV">
                      <Form.Label className="titleLabelCV">Task 3 </Form.Label>
                      <Form.Control
                        id={`task2${i}`}
                        name={`task2-${i}-2`}
                        type="text"
                        className="form-control taskCV marginLeft"
                        value={this.state.experience[i].task[2]}
                        onChange={(e) => this.handleChangeExpTasks(e)}
                      />
                    </div>
                  </div>
                  <div className="PairMakerJob">
                    <div className="rowCV">
                      <Form.Label className="titleLabelCV">City </Form.Label>
                      <Form.Control
                        id={`place${i}`}
                        name={`place-${i}`}
                        type="text"
                        className="form-control taskCV marginLeft"
                        value={this.state.experience[i].place}
                        onChange={(e) => this.handleChangeExpTitle(e)}
                      />
                    </div>
                    <div className="rowCV">
                      <Form.Label
                        className="titleLabelCV"
                        style={{
                          marginTop: 0.1 + "rem",
                          lineHeight: "initial",
                        }}
                      >
                        Start Date{" "}
                      </Form.Label>
                      <Form.Control
                        id={`dateStart${i}`}
                        name={`dateStart-${i}`}
                        type="month"
                        className="form-control taskCV marginLeft"
                        value={this.state.experience[i].dateStart}
                        onChange={(e) => this.handleChangeExpTitle(e)}
                      />
                    </div>
                  </div>
                  <div className="PairMakerJob">
                    <div style={{ marginLeft: "-1rem" }} className="rowCV">
                      <Form.Label
                        className="titleLabelCV"
                        style={{
                          marginTop: 0.1 + "rem",
                          lineHeight: "initial",
                        }}
                      >
                        Finish Date{" "}
                      </Form.Label>
                      <Form.Control
                        id={`dateFinish${i}`}
                        name={`dateFinish-${i}`}
                        type="month"
                        className="form-control taskCV marginLeft"
                        value={this.state.experience[i].dateFinish}
                        onChange={(e) => this.handleChangeExpTitle(e)}
                      />
                    </div>
                    <div className="rowCV">
                      <Form.Label style={{ fontSize: 0.9 + "rem" }}>
                        Leave this empty if you are currently working here
                      </Form.Label>
                    </div>
                  </div>
                </Form.Group>
              );
            })}
            <img
              onClick={(e) => this.handlePlus(e)}
              className="imgSkills"
              alt="plus icon"
              src="/assets/images/PLUS.png"
            />
            <div className="PairMakerJob">
              <label>Education: </label>
            </div>
            {this.state.education.map((elem, i) => {
              return (
                <Form.Group key={i} className="formInput">
                  <div className="PairMakerJob">
                    <div className="rowCV">
                      <Form.Label className="titleLabelCV">Title </Form.Label>
                      <Form.Control
                        id={`title${i}`}
                        name={`title-${i}`}
                        type="text"
                        className="form-control taskCV marginLeft"
                        value={this.state.education[i].title}
                        onChange={(e) => this.handleChangeEdu(e)}
                      />
                    </div>
                    <div className="rowCV">
                      <Form.Label className="titleLabelCV">City </Form.Label>
                      <Form.Control
                        id={`place${i}`}
                        name={`place-${i}`}
                        type="text"
                        className="form-control taskCV marginLeft"
                        value={this.state.education[i].place}
                        onChange={(e) => this.handleChangeEdu(e)}
                      />
                    </div>
                  </div>

                  <div className="PairMakerJob">
                    <div className="rowCV">
                      <Form.Label
                        className="titleLabelCV"
                        style={{
                          marginTop: 0.1 + "rem",
                          lineHeight: "initial",
                        }}
                      >
                        Start Date{" "}
                      </Form.Label>
                      <Form.Control
                        id={`dateStart${i}`}
                        name={`dateStart-${i}`}
                        type="month"
                        className="form-control taskCV marginLeft"
                        value={this.state.education[i].dateStart}
                        onChange={(e) => this.handleChangeEdu(e)}
                      />
                    </div>
                    <div className="rowCV">
                      <div className="rowCV">
                        <Form.Label
                          className="titleLabelCV"
                          style={{
                            marginTop: 0.1 + "rem",
                            lineHeight: "initial",
                          }}
                        >
                          Finish Date{" "}
                        </Form.Label>
                        <Form.Control
                          id={`dateFinish${i}`}
                          name={`dateFinish-${i}`}
                          type="month"
                          className="form-control taskCV marginLeft"
                          value={this.state.education[i].dateFinish}
                          onChange={(e) => this.handleChangeEdu(e)}
                        />
                      </div>
                    </div>
                  </div>
                </Form.Group>
              );
            })}
            <img
              onClick={(e) => this.handlePlusEdu(e)}
              className="imgSkills"
              alt="plus icon"
              src="/assets/images/PLUS.png"
            />
            {this.state.valid ? (
              <p className="warning">{this.state.valid}</p>
            ) : null}
            <div>
              <button className="btn btn-m buttonSecondary paddingIdBtn">
                Next
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default CVForm;
