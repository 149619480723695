import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import Countries from "../../utils/country";
import Dates from "../../utils/dates";

class RegisterCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      repeatPassword: "",
      address: "",
      postcode: "",
      countryCode: "+",
      phone: "",
      country: "-Select your country-",
      year: "year",
      month: "month",
      day: "day",
      Terms: false,
      tempCountry: "",
      valid: "",
    };
  }
  componentDidMount() {
    const user = JSON.parse(window.localStorage.getItem("user"));
    if (user) {
      this.setState({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: user.password,
        address: user.address,
        postcode: user.postcode,
        countryCode: user.countryCode,
        phone: user.phone,
        country: user.country,
        year: user.year,
        month: user.month,
        day: user.day,
        tempCountry: user.tempCountry,
        Terms: window.localStorage.getItem("terms") === "true" ? true : false,
        sent: false,
      });
    }

    window.localStorage.removeItem("terms");
    window.localStorage.removeItem("user");
  }
  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    type === "checkbox"
      ? this.setState({
          [name]: checked,
        })
      : this.setState({
          [name]: value,
        });
  };
  handleSelect = (event) => {
    const { name, value } = event.target;
    const arrvalue = value.split("/");
    this.setState({
      [name]: value,
      country: arrvalue[0],
      countryCode: arrvalue[1],
    });
  };
  handleClickLink = (event) => {
    event.preventDefault();
    window.localStorage.setItem("user", JSON.stringify(this.state));
    window.location = "/terms";
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let today = new Date();
    let ageString =
      "" + this.state.year + "-" + this.state.month + "-" + this.state.day;
    let birthDate = new Date(ageString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    let validAge = age >= 18;

    const emailPattern = new RegExp(
      // eslint-disable-next-line
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );

    const passwordPattern = new RegExp(
      // eslint-disable-next-line
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    );
    if (
      this.state.email === "" ||
      this.state.password === "" ||
      this.state.repeatPassword === "" ||
      this.state.firstName === "" ||
      this.state.lastName === "" ||
      this.state.address === "" ||
      this.state.postcode === "" ||
      this.state.countryCode === "+" ||
      this.state.phone === "" ||
      this.state.country === "-Select your country-" ||
      this.state.year === "year" ||
      this.state.month === "month" ||
      this.state.day === "day"
    ) {
      this.setState({
        valid: "All fields are required",
      });
    } else if (!this.state.Terms) {
      this.setState({
        valid: "You must accept terms and conditions to continue",
      });
    } else if (!emailPattern.test(this.state.email)) {
      this.setState({
        valid: "Please enter valid email address",
      });
    } else if (!passwordPattern.test(this.state.password)) {
      this.setState({
        valid:
          "Please enter a  password with at least eight characters, at least one letter, one number and one special character. Example: abcdE123!",
      });
    } else if (this.state.password !== this.state.repeatPassword) {
      this.setState({
        valid: "Passwords does not match.",
      });
    } else if (!validAge) {
      this.setState({
        valid: "You don't have 18 years or more",
      });
    } else {
      this.setState({
        valid: "",
      });
      const date = `${this.state.year}-${this.state.month}-${this.state.day}`;

      const dataFetch = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email.toLowerCase(),
          password: this.state.password,
          address: this.state.address,
          postcode: this.state.postcode,
          countryCode: this.state.countryCode,
          phone: this.state.phone,
          country: this.state.country,
          DOB: date,
          tokenSec: localStorage.getItem("mail"),
        }),
      };
      const response = await fetch("/api/account/signup", dataFetch);
      const data = await response.json();
      if (!data.ok) {
        this.setState({
          valid: data.error,
        });
      } else {
        localStorage.setItem("mail", data.tokenSec);
        this.setState({
          sent: true,
        });
      }
    }
  };
  handleClick = async (e) => {
    this.setState({
      valid: "",
    });

    await fetch("/api/mails/resendtoken/" + this.state.email);
    this.setState({
      valid: "Resent!",
    });
  };
  render(props) {
    if (!this.state.sent) {
      return (
        <div className="cardContainer">
          <h2 className="titleRegister">SIGN UP</h2>
          <p className="bottomText">
            We will requesting a form of ID later on, please use your name as it
            appears on
          </p>
          <Form onSubmit={(e) => this.handleSubmit(e)} className="authForm">
            <Form.Group className="formInput">
              <div className="PairMaker">
                <Form.Control
                  className="widthDel"
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.firstName}
                />

                <Form.Control
                  className="widthDel"
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.lastName}
                />
              </div>
            </Form.Group>
            <Form.Group className="formInput">
              <Form.Control
                type="text"
                id="address"
                name="address"
                placeholder="Address"
                required
                onChange={(e) => this.handleChange(e)}
                value={this.state.address}
              />
            </Form.Group>
            <Form.Group className="formInput">
              <div className="PairMaker">
                <Form.Control
                  className="widthDel"
                  type="text"
                  id="postcode"
                  name="postcode"
                  placeholder="Postcode"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.postcode}
                />

                <select
                  id="tempCountry"
                  name="tempCountry"
                  className="form-control widthDel marginLeft"
                  value={this.state.tempCountry}
                  onChange={(e) => this.handleSelect(e)}
                >
                  <option value="-Select your country-">
                    -Select your country-
                  </option>
                  {Countries.map((country) => {
                    return (
                      <option
                        key={country.name + country.value}
                        value={country.name + "/" + country.mcode}
                      >
                        {country.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Form.Group>
            <Form.Group className="formInput">
              <div className="PairMaker">
                <select
                  id="countryCode"
                  name="countryCode"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.countryCode}
                  className="form-control numericSelect"
                >
                  <option value="+">+</option>
                  {Countries.map((country) => {
                    return (
                      <option
                        key={country.value + country.name}
                        value={country.mcode}
                      >
                        {country.mcode + " " + country.ccode}
                      </option>
                    );
                  })}
                </select>
                <Form.Control
                  className="phoneInput"
                  type="number"
                  id="phone"
                  name="phone"
                  placeholder="Phone number"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.phone}
                />
              </div>
            </Form.Group>
            <Form.Group className="formInput">
              <div className="PairMaker">
                <p className="dateLabel">Date of birth</p>
                <div className="dateOrganizer">
                  <select
                    id="year"
                    name="year"
                    value={this.state.year}
                    onChange={(e) => this.handleChange(e)}
                    className="form-control dateSelect
									"
                  >
                    <option value="year">Year</option>
                    {Dates.year.map((year) => {
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="month"
                    name="month"
                    value={this.state.month}
                    onChange={(e) => this.handleChange(e)}
                    className="form-control dateSelect
									"
                  >
                    <option value="month">Month</option>
                    {Dates.month.map((month) => {
                      return (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="day"
                    name="day"
                    value={this.state.day}
                    onChange={(e) => this.handleChange(e)}
                    className="form-control dateSelect
									"
                  >
                    <option value="day">Day</option>
                    {Dates.day.map((day) => {
                      return (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </Form.Group>
            <Form.Group className="formInput">
              <div className="PairMaker">
                <Form.Control
                  className="widthDel"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.email}
                />

                <Form.Control
                  className="widthDel"
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.password}
                />
              </div>
            </Form.Group>
            <Form.Group className="formInput">
              <div className="PairMaker">
                <span className="rowCustom newField">
                  <Checkbox
                    checked={this.state.Terms}
                    color="primary"
                    id="Terms"
                    inputProps={{
                      id: "Terms",
                      onChange: (e) => this.handleChange(e),
                      name: "Terms",
                    }}
                  />
                  <label>
                    I agree with the{" "}
                    <a onClick={(e) => this.handleClickLink(e)} href="/terms">
                      terms and conditions{" "}
                    </a>
                  </label>
                </span>
                <Form.Control
                  className="widthDel"
                  type="password"
                  id="repeatPassword"
                  name="repeatPassword"
                  placeholder="Confirm password"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.repeatPassword}
                />
              </div>
            </Form.Group>
            {
              //Linea de separacion, todo deberia estar por encima de esto
            }
            {this.state.valid ? (
              <p className="warning">{this.state.valid}</p>
            ) : null}
            <button className="btn btn-m buttonSecondary">
              Create account
            </button>
          </Form>
          <p className="bottomText">
            Do you already have an account? <a href="/login">Log in</a>
          </p>
        </div>
      );
    } else {
      return (
        <div className="cardContainerPass">
          <h2 className="title passTitle">SIGN UP</h2>
          <p className="extraText">
            An email have been sent to {this.state.email} with further
            instructions about how to validate your account.
            <br />
            <br />
            Please remember to look in important, promotions and spam tags.
          </p>
          <br />
          <p className="smallText">Didn't receive a message?</p>
          <button
            className="col-5 btn btn-m buttonSecondary"
            onClick={(e) => this.handleClick(e)}
          >
            Resend Email
          </button>
          {this.state.valid ? (
            <p className="warning">{this.state.valid}</p>
          ) : null}
          <p className="bottomText">
            Do you already have an account?
            <a href="/login"> Log in</a>
          </p>
        </div>
      );
    }
  }
}

export default RegisterCard;
