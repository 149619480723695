import React from "react";

function Stepsline() {
	return (
		<div className="containerTimeTerms">
			<ul className="progressbarTerms">
				<li className="manager">Step 1</li>
				<li className="manager">Step 2</li>
				<li id="active" className="active manager">
					Step 3
				</li>
			</ul>
		</div>
	);
}

export default Stepsline;
