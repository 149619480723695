import React, { Fragment } from "react";

function MailSend(props) {
  return (
    <Fragment>
      <div className="cardJob">
        <h2 className="titleRegister">SKILLS</h2>
        <p className="name">Hello {props.name}</p>
        <p className="bottomText textchekid marginEnglishText">
          You will receive a Logic exam, when you pass this test you will then receive your test for 
          {props.skills.map((elem) => {
            return " " + elem.skill + ", ";
          })}
        </p>
        <img
          className="englishImage"
          alt="Woman taking test"
          src="/assets/images/mailsend.png"
        />
        <div className="buttomsRow">
          <button
            className="btn btn-m buttonSecondaryEng paddingIdBtn"
            onClick={(e) => {
              e.preventDefault();
              window.location = "/cvLoad";
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default MailSend;
