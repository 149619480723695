import React, { Component, Fragment } from "react";
import FileSaver from "file-saver";
import "./admin.css";

class Admin extends Component {
	constructor() {
		super();
		this.state = {
			users: [],
			change: false,
			order: false,
			englishUser: null,
			skillUser: null,
			userForChange: null,
			adminInput: "",
		};
	}

	handleInput = (event) => {
		this.setState({
			adminInput: event.target.value,
		});
	};
	handleEnglishClick = (event, user) => {
		event.preventDefault();
		this.setState({
			englishUser: user,
		});
	};

	handleSkillClick = (event, user, skill) => {
		event.preventDefault();
		this.setState({
			skillUser: skill,
			userForChange: user,
		});
	};

	handleEnglishSend = async (e, user, email) => {
		e.preventDefault();
		const dataFetch = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				cert: `https://www.efset.org/cert/${this.state.adminInput}`,
			}),
		};
		const response = await fetch(`/admin/englishCert/${email}`, dataFetch);
		const data = await response.json();
		if (data.ok) {
			this.setState({
				englishUser: null,
				adminInput: "",
				users: this.state.users.map((elem) => {
					if (elem === user) {
						elem.english.link = `https://www.efset.org/cert/${this.state.adminInput}`;
					}
					return elem;
				}),
			});
		}
	};

	handleSkillSend = async (e, user, email, number) => {
		e.preventDefault();
		const dataFetch = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				score: this.state.adminInput,
				skill: this.state.skillUser,
			}),
		};
		const response = await fetch(
			`/admin/ChangeSkillNote/${email}`,
			dataFetch
		);
		const data = await response.json();
		if (data.ok) {
			let skillNum = "skill" + number;
			this.setState({
				skillUser: null,
				userForChange: null,
				adminInput: "",
				users: this.state.users.map((elem) => {
					if (elem === user) {
						elem[skillNum].score = this.state.adminInput;
					}
					return elem;
				}),
			});
		}
	};

	dateOfBirthFormat = (date) => {
		let dateSplitted = date.split("T")[1].split(":");
		const dateData = date.split("T")[0];
		dateSplitted.shift();
		dateSplitted.unshift("22");
		dateSplitted = dateSplitted.join(":");
		dateSplitted = "T" + dateSplitted;
		dateSplitted = dateData + dateSplitted;
		const originalDate = new Date(dateSplitted);
		const dateArray = [
			originalDate.getDate() < 10
				? "0" + originalDate.getDate()
				: originalDate.getDate(),
			originalDate.getMonth() + 1 < 10
				? "0" + (originalDate.getMonth() + 1)
				: originalDate.getMonth() + 1,
			originalDate.getFullYear(),
		];

		return dateArray.join("/");
	};
	lastInteractionFormat = (date) => {
		const originalDate = new Date(date);
		const dateArray = [
			originalDate.getDate() < 10
				? "0" + originalDate.getDate()
				: originalDate.getDate(),
			originalDate.getMonth() + 1 < 10
				? "0" + (originalDate.getMonth() + 1)
				: originalDate.getMonth() + 1,
			originalDate.getFullYear(),
			originalDate.getHours() < 10
				? "0" + originalDate.getHours()
				: originalDate.getHours(),
			originalDate.getMinutes() < 10
				? "0" + originalDate.getMinutes()
				: originalDate.getMinutes(),
		];
		return (
			dateArray[0] +
			"/" +
			dateArray[1] +
			"/" +
			dateArray[2] +
			" " +
			dateArray[3] +
			":" +
			dateArray[4]
		);
	};
	handleChecked = async (user) => {
		const response = await fetch("/admin/valUser/" + user.email);
		const data = await response.json();
		if (data.ok) {
			const index = this.state.users.indexOf(user);
			this.state.users[index].checked = !this.state.users[index].checked;
			fetch(`/api/mails/idconfirm/${user.email}`);
			this.setState({
				change: !this.state.change,
			});
		}
	};
	handleDownload = async () => {
		const response = await fetch("/admin/downloadUsersInfo");
		const blob = await response.blob();
		FileSaver.saveAs(blob, "report.txt");
	};
	async componentDidMount() {
		const response = await fetch("/admin/allusers");
		const data = await response.json();
		this.setState({
			users: data.user,
		});
	}
	handleDelete = async (event, email) => {
		const response = await fetch(`/admin/delete/${email}`);
		const data = await response.json();
		if (data.ok) {
			this.setState({
				users: this.state.users.filter((user) => user.email !== email),
			});
		}
	};

	handleAlphabeticSort = (event, key) => {
		if (/^skill/.test(key)) {
			this.setState({
				users: this.state.users.sort((a, b) => {
					if (
						a[key].skill.toLowerCase() < b[key].skill.toLowerCase()
					) {
						return -1;
					}
					if (
						a[key].skill.toLowerCase() < b[key].skill.toLowerCase()
					) {
						return 1;
					}
					return 0;
				}),
			});
		} else {
			this.setState({
				users: this.state.users.sort((a, b) => {
					if (a[key].toLowerCase() < b[key].toLowerCase()) {
						return -1;
					}
					if (a[key].toLowerCase() < b[key].toLowerCase()) {
						return 1;
					}
					return 0;
				}),
			});
		}
	};
	handleNumericSort = (event, key) => {
		if (!this.state.order) {
			this.setState({
				order: true,
				users: this.state.users.sort((a, b) => {
					let scoreA =
						a[key].score === "Pending" ||
						a[key].score === "Not taken" ||
						a[key].score === "-"
							? 0
							: parseInt(a[key].score);
					let scoreB =
						b[key].score === "Pending" ||
						b[key].score === "Not taken" ||
						b[key].score === "-"
							? 0
							: parseInt(b[key].score);
					return scoreA - scoreB;
				}),
			});
		} else {
			this.setState({
				order: false,
				users: this.state.users.sort((a, b) => {
					let scoreA =
						a[key].score === "Pending" ||
						a[key].score === "Not taken" ||
						a[key].score === "-"
							? 0
							: parseInt(a[key].score);
					let scoreB =
						b[key].score === "Pending" ||
						b[key].score === "Not taken" ||
						b[key].score === "-"
							? 0
							: parseInt(b[key].score);
					return scoreB - scoreA;
				}),
			});
		}
	};

	render() {
		return (
			<div className="columnAdmin">
				<div className="rowAdmin">
					<h1 className="adminTitle">ADMIN</h1>
					<div>
						<button
							className="btn btn-m buttonSecondary paddingIdBtn buttomAdmin"
							onClick={(e) => this.handleDownload(e)}
						>
							Download in CSV
						</button>
						<button
							className="btn btn-m adminSecondButton paddingIdBtn buttomAdmin"
							onClick={(e) => {
								window.location = "/admin/partner";
							}}
						>
							Manage Partners
						</button>
					</div>
				</div>
				<table className="adminTable">
					<thead>
						<tr>
							<th className="invisiCell"></th>
							<th className="headerTable cellTable">
								Aprove selfie
							</th>
							<th className="headerTable cellTable">
								Name
								<span
									onClick={(e) => {
										this.handleAlphabeticSort(e, "name");
									}}
								>
									&#9660;
								</span>
							</th>
							<th className="headerTable cellTable">
								Last Name
								<span
									onClick={(e) => {
										this.handleAlphabeticSort(
											e,
											"lastName"
										);
									}}
								>
									&#9660;
								</span>
							</th>
							<th className="headerTable cellTable">DOB</th>
							<th className="headerTable cellTable">
								Email{" "}
								<span
									onClick={(e) => {
										this.handleAlphabeticSort(e, "email");
									}}
								>
									&#9660;
								</span>
							</th>
							<th className="headerTable cellTable">
								Skill1
								<span
									onClick={(e) => {
										this.handleAlphabeticSort(e, "skill1");
									}}
								>
									&#9660;
								</span>
							</th>
							<th
								className="headerTable cellTable"
								onClick={(e) => {
									this.handleNumericSort(e, "skill1");
								}}
							>
								Score{" "}
								{this.state.order ? (
									<span
										onClick={(e) => {
											this.handleAlphabeticSort(
												e,
												"skill1"
											);
										}}
									>
										{" "}
										&#9660;
									</span>
								) : (
									<span
										onClick={(e) => {
											this.handleAlphabeticSort(
												e,
												"skill1"
											);
										}}
									>
										{" "}
										&#9650;
									</span>
								)}
							</th>
							<th className="headerTable cellTable">
								Skill2
								<span
									onClick={(e) => {
										this.handleAlphabeticSort(e, "skill2");
									}}
								>
									&#9660;
								</span>
							</th>
							<th
								className="headerTable cellTable"
								onClick={(e) => {
									this.handleNumericSort(e, "skill2");
								}}
							>
								Score
								{this.state.order ? (
									<span
										onClick={(e) => {
											this.handleAlphabeticSort(
												e,
												"skill2"
											);
										}}
									>
										{" "}
										&#9660;
									</span>
								) : (
									<span
										onClick={(e) => {
											this.handleAlphabeticSort(
												e,
												"skill2"
											);
										}}
									>
										{" "}
										&#9650;
									</span>
								)}
							</th>
							<th className="headerTable cellTable">
								Skill3
								<span
									onClick={(e) => {
										this.handleAlphabeticSort(e, "skill3");
									}}
								>
									&#9660;
								</span>
							</th>
							<th
								className="headerTable cellTable"
								onClick={(e) => {
									this.handleNumericSort(e, "skill3");
								}}
							>
								Score
								{this.state.order ? (
									<span
										onClick={(e) => {
											this.handleAlphabeticSort(
												e,
												"skill3"
											);
										}}
									>
										{" "}
										&#9660;
									</span>
								) : (
									<span
										onClick={(e) => {
											this.handleAlphabeticSort(
												e,
												"skill3"
											);
										}}
									>
										{" "}
										&#9650;
									</span>
								)}
							</th>
							<th className="headerTable cellTable">
								Skill4
								<span
									onClick={(e) => {
										this.handleAlphabeticSort(e, "skill4");
									}}
								>
									&#9660;
								</span>
							</th>
							<th
								className="headerTable cellTable"
								onClick={(e) => {
									this.handleNumericSort(e, "skill4");
								}}
							>
								Score
								{this.state.order ? (
									<span
										onClick={(e) => {
											this.handleAlphabeticSort(
												e,
												"skill4"
											);
										}}
									>
										{" "}
										&#9660;
									</span>
								) : (
									<span
										onClick={(e) => {
											this.handleAlphabeticSort(
												e,
												"skill4"
											);
										}}
									>
										{" "}
										&#9650;
									</span>
								)}
							</th>
							<th className="headerTable cellTable">English</th>
							<th className="headerTable cellTable">
								Salary Exp
							</th>
							<th className="headerTable cellTable">
								Country
								<span
									onClick={(e) => {
										this.handleAlphabeticSort(e, "country");
									}}
								>
									&#9660;
								</span>
							</th>
							<th className="headerTable cellTable">Selfie</th>
							<th className="headerTable cellTable">Phone</th>
							<th className="headerTable cellTable">
								Last Check-in
							</th>
						</tr>
					</thead>
					<tbody>
						{this.state.users.map((user) => (
							<tr key={user.email}>
								<td>
									{" "}
									<button
										onClick={(e) =>
											this.handleDelete(e, user.email)
										}
										className="deleteIcon"
									>
										&#9747;
									</button>
								</td>
								<td className="cellTable">
									<div className="rowImage">
										<a
											href={
												"/admin/valimage/" + user.email
											}
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												className="rounded-circle navImage"
												src={
													user.avatar
														? user.avatar
														: "/assets/images/avplaceholder.png"
												}
												alt="User avatar"
											/>
										</a>
										<div className="toggles">
											<span>
												<input
													type="checkbox"
													name="newsletter"
													id={user.email}
													className="ios-toggle checkboxGreen"
													checked={user.checked}
													onChange={(e) =>
														this.handleChecked(user)
													}
												/>
												<label
													htmlFor="newsletter"
													className="checkbox-label checkboxGreen"
													onClick={(e) =>
														this.handleChecked(user)
													}
													data-off="off"
													data-on="on"
												></label>
											</span>
										</div>
									</div>
								</td>
								<td className="cellTable">{user.name}</td>
								<td className="cellTable">{user.lastName}</td>
								<td className="cellTable">
									{this.dateOfBirthFormat(user.DOB)}
								</td>
								<td className="cellTable">{user.email}</td>
								<td className="cellTable">
									{user.skill1 ? user.skill1.skill : "-"}
								</td>
								<td className="cellTable">
									{user.skill1 ? user.skill1.score : "-"}
									{user.skill1 &&
									(user.skill1.skill === "CSS" ||
										user.skill1.skill === "HTML") ? (
										<Fragment>
											{"    "}
											<img
												className="rounded-circle smallImageAdmin"
												alt="edit icon"
												src="/assets/images/edit.png"
												onClick={(e) =>
													this.handleSkillClick(
														e,
														user.email,
														user.skill1.skill
													)
												}
											/>
										</Fragment>
									) : null}
									{this.state.userForChange === user.email &&
									this.state.skillUser ===
										user.skill1.skill ? (
										<div>
											<p>
												Score:
												<input
													type="text"
													name="Score"
													value={
														this.state.adminInput
													}
													onChange={(e) =>
														this.handleInput(e)
													}
												></input>
											</p>
											<button
												onClick={(e) =>
													this.handleSkillSend(
														e,
														user,
														user.email,
														"1"
													)
												}
											>
												Send
											</button>
										</div>
									) : null}
								</td>
								<td className="cellTable">
									{user.skill2 ? user.skill2.skill : "-"}
								</td>
								<td className="cellTable">
									{user.skill2 ? user.skill2.score : "-"}
									{user.skill2 &&
									(user.skill2.skill === "CSS" ||
										user.skill2.skill === "HTML") ? (
										<Fragment>
											{"    "}
											<img
												className="rounded-circle smallImageAdmin"
												alt="edit icon"
												src="/assets/images/edit.png"
												onClick={(e) =>
													this.handleSkillClick(
														e,
														user.email,
														user.skill2.skill
													)
												}
											/>
										</Fragment>
									) : null}
									{this.state.userForChange === user.email &&
									this.state.skillUser ===
										user.skill2.skill ? (
										<div>
											<p>
												Score:
												<input
													type="text"
													name="Score"
													value={
														this.state.adminInput
													}
													onChange={(e) =>
														this.handleInput(e)
													}
												></input>
											</p>
											<button
												onClick={(e) =>
													this.handleSkillSend(
														e,
														user,
														user.email,
														"2"
													)
												}
											>
												Send
											</button>
										</div>
									) : null}
								</td>
								<td className="cellTable">
									{user.skill3 ? user.skill3.skill : "-"}
								</td>
								<td className="cellTable">
									{user.skill3 ? user.skill3.score : "-"}
									{user.skill3 &&
									(user.skill3.skill === "CSS" ||
										user.skill3.skill === "HTML") ? (
										<Fragment>
											{"    "}
											<img
												className="rounded-circle smallImageAdmin"
												alt="edit icon"
												src="/assets/images/edit.png"
												onClick={(e) =>
													this.handleSkillClick(
														e,
														user.email,
														user.skill3.skill
													)
												}
											/>
										</Fragment>
									) : null}
									{this.state.userForChange === user.email &&
									this.state.skillUser ===
										user.skill3.skill ? (
										<div>
											<p>
												Score:
												<input
													type="text"
													name="Score"
													value={
														this.state.adminInput
													}
													onChange={(e) =>
														this.handleInput(e)
													}
												></input>
											</p>
											<button
												onClick={(e) =>
													this.handleSkillSend(
														e,
														user,
														user.email,
														"3"
													)
												}
											>
												Send
											</button>
										</div>
									) : null}
								</td>
								<td className="cellTable">
									{user.skill4 ? user.skill4.skill : "-"}
								</td>
								<td className="cellTable">
									{user.skill4 ? user.skill4.score : "-"}
									{user.skill4 &&
									(user.skill4.skill === "CSS" ||
										user.skill4.skill === "HTML") ? (
										<Fragment>
											{"    "}
											<img
												className="rounded-circle smallImageAdmin"
												alt="edit icon"
												src="/assets/images/edit.png"
												onClick={(e) =>
													this.handleSkillClick(
														e,
														user.email,
														user.skill4.skill
													)
												}
											/>
										</Fragment>
									) : null}
									{this.state.userForChange === user.email &&
									this.state.skillUser ===
										user.skill4.skill ? (
										<div>
											<p>
												Score:
												<input
													type="text"
													name="Score"
													value={
														this.state.adminInput
													}
													onChange={(e) =>
														this.handleInput(e)
													}
												></input>
											</p>
											<button
												onClick={(e) =>
													this.handleSkillSend(
														e,
														user,
														user.email,
														"4"
													)
												}
											>
												Send
											</button>
										</div>
									) : null}
								</td>
								<td className="cellTable">
									<a
										href={
											user.english
												? user.english.link
												: null
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										{user.english ? "link" : null}
									</a>
									{this.state.englishUser !== user.email ? (
										<Fragment>
											{"    "}
											<img
												className="rounded-circle smallImageAdmin"
												alt="edit icon"
												src="/assets/images/edit.png"
												onClick={(e) =>
													this.handleEnglishClick(
														e,
														user.email
													)
												}
											/>
										</Fragment>
									) : null}
									{this.state.englishUser === user.email ? (
										<div>
											<p>
												https://www.efset.org/cert/
												<input
													type="text"
													name="english"
													value={
														this.state.adminInput
													}
													onChange={(e) =>
														this.handleInput(e)
													}
												></input>
											</p>
											<button
												onClick={(e) =>
													this.handleEnglishSend(
														e,
														user,
														user.email
													)
												}
											>
												Send
											</button>
										</div>
									) : null}
								</td>
								<td className="cellTable">
									{user.job.salaryLow && user.job.salaryHigh
										? user.job.salaryLow +
										  " - " +
										  user.job.salaryHigh
										: " - "}
								</td>
								<td className="cellTable">{user.country}</td>
								<td className="cellTable">
									<a
										href={"/admin/valimage/" + user.email}
										target="_blank"
										rel="noopener noreferrer"
									>
										{user.selfie ? "True" : "-"}
									</a>
								</td>
                <td className="cellTable">{user.phone}</td>
								<td className="cellTable">
									{this.lastInteractionFormat(
										user.lastInteraction
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

export default Admin;

//Kharleann 5f22f41542dbb42ce39fe1b1
//asdasd 5f22f3cc42dbb42ce39fe1b0
