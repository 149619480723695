import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const RadialBar = (props) => {
  return (
    <div className="radialbar">
      <CircularProgressbar
        strokeWidth={10}
        value={props.percentage}
        text={`${props.percentage}%`}
        styles={buildStyles({
          pathTransitionDuration: 0.5,
          // Colors
          pathColor: `var(--primary)`,
          textColor: `var(--primary)`,
          trailColor: "#acc8dd",
          backgroundColor: "#acc8dd",
        })}
      />
    </div>
  );
};

export default RadialBar;
