import React, { Component } from "react";
import ConfirmCard from "./components/ConfirmCard";
import StepsLine from "./timeline/StepsLine";

class ConfirmAccount extends Component {
  render() {
    return (
      <div className="container-login">
        <div className="container-blue" style={{ padding: "20px" }}>
          <img
            className="imageTerms"
            src="/assets/images/loginRegister.png"
            alt="Woman looking profiles"
          />
        </div>

        <div className="rounded-lg container-form">

          <ConfirmCard />
        </div>
      </div>
    );
  }
}

export default ConfirmAccount;
