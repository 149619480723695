import React, { Component } from "react";
import { Form } from "react-bootstrap";

class PasswordCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      valid: "",
    };
  }
  async componentDidMount() {
    try {
      let response = await fetch("/api/app/current-user/skills");
      let data = await response.json();
      if (data.error) {
        // window.location = "/dashboard";
      } else {
        const temp = data.skills.filter((elem) => {
          return elem.score === "Not taken" || elem.score === "Pending";
        }).length;
        // if (temp > 0) {
        //   window.location = "/dashboard";
        // } else if (!data.english) {
        //   window.location = "/english";
        // }
      }
    } catch (error) {
      console.log(error);
    }
  }
  handleChange = (event) => {
    const { name, files } = event.target;
    const data = new FormData();
    data.append("photo", files[0]);
    this.setState({
      [name]: files[0],
      data,
    });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      valid: "",
    });
    if (this.state.data) {
      const ext = this.state.file.name.split(".").pop();
      if (
        this.state.file.size < 6000000 &&
        (ext === "jpg" || ext === "png" || ext === "jpeg" || ext === "pdf")
      ) {
        const dataFetch = {
          method: "POST",
          headers: {},
          body: this.state.data,
        };
        localStorage.removeItem("mail");
        const response = await fetch("/api/app/checkID", dataFetch);
        const data = await response.json();
        if (data.ok) {
          window.location = "/dashboard";
        } else {
          this.setState({
            valid: data.error,
          });
        }
      } else {
        this.setState({
          valid:
            "File must be smaller than 5MB and we only accept the following formats: JPG, PNG, JPEG, PDF",
        });
      }
    } else {
      this.setState({
        valid: "Click on the icon to upload a file",
      });
    }
  };
  handleClick = async (event) => {
    event.preventDefault();
  };
  render(props) {
    return (
      <div className="cardContainerPass">
        <h2 className="title passTitle">PROFILE</h2>
        <p>Hello {this.props.name}</p>
        <p className="bottomText textchekid">
          Now it is time to confirm your identity, please upload a selfie of
          yourself holding yout passport or national ID. Please make sure your
          id can be seen and it is readable. After confirming your identity,
          your account will be active and you can log your skills.
        </p>

        <Form className="columnCloud" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="CloudContainer">
            <label htmlFor="file">
              <img
                className="Cloudicon"
                alt="cloud icon"
                src="/assets/images/upload.png"
              />
            </label>
          </div>
          <input
            type="file"
            name="file"
            id="file"
            style={{ display: "none" }}
            onChange={(e) => this.handleChange(e)}
          />
          {this.state.file ? <p>{this.state.file.name}</p> : null}
          {this.state.valid ? (
            <p className="warning text-center">{this.state.valid}</p>
          ) : null}
          <button className="btn btn-m buttonSecondary paddingIdBtn">
            Upload Photo
          </button>
        </Form>
      </div>
    );
  }
}

export default PasswordCard;
