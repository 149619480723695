import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import "./skills.css";
class JobForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seniority0: "",
      skill0: "",
      skills: [],
      valid: "",
    };
  }
  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    type === "checkbox"
      ? this.setState({
          [name]: checked,
        })
      : this.setState({
          [name]: value,
        });
  };
  async componentDidMount() {
    const response0 = await fetch("/codility/tests");
    const data0 = await response0.json();
    await data0.sort((a, b) => {
      if (a.test.toLowerCase() < b.test.toLowerCase()) {
        return -1;
      }
      if (a.test.toLowerCase() > b.test.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    await this.setState({
      skills: data0,
    });
    const token = await window.location.href.split("/").pop();
    const response = await fetch("/api/app/data/" + token);
    const data = await response.json();

    for (let i = 0; i < data0.length; i++) {
      const element = data0[i];
      if (element.test === data.skill.skill) {
        var index = i;
      }
    }

    if (data.ok) {
      this.setState({
        seniority0: data.skill.seniority,
        skill0: index,
      });
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const skills = {
      seniority: this.state.seniority0,
      skill: this.state.skills[this.state.skill0].test,
      invitatoin: this.state.skills[this.state.skill0].invitation,
    };
    const dataFetch = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        seniority: skills.seniority,
        skill: skills.skill,
      }),
    };
    const token = await window.location.href.split("/").pop();
    const response = await fetch("/api/app/editSkill/" + token, dataFetch);
    const data = await response.json();
    if (data.ok) {
      window.location = "/dashboard";
    } else {
      this.setState({ valid: data.error + this.state.valid });
    }
  };
  render(props) {
    return (
      <Fragment>
        <div className="cardJob">
          <h2 className="titleRegister">SKILLS</h2>
          <p>Hello {this.props.name}</p>
          <p className="bottomTextJob textchekid">
            It is now time to tell us what you are good at it. Please note you
            will have to do a Test which could take up to 1 hour for each skill
            you log, so please focus on those you are really good at it.
          </p>
          <Form className="formJob" onSubmit={(e) => this.handleSubmit(e)}>
            <Form.Group className="formInput">
              <div className="PairMakerJob">
                {this.state.skill0 !== "" && (
                  <span className="rowSkill">
                    <label>Seniority</label>
                    <select
                      id="seniority0"
                      name="seniority0"
                      className="form-control selectSkill"
                      value={this.state.seniority0}
                      onChange={(e) => this.handleChange(e)}
                    >
                      <option value={""}>-Select seniority level- </option>
                      {this.state?.skills[this.state?.skill0]?.seniority?.map(
                        (elem) => {
                          return (
                            <option key={elem + this.state.skill0} value={elem}>
                              {elem}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </span>
                )}
                <span className="rowSkill">
                  <label className="marginLeftSkills">Skill</label>
                  <select
                    id="skill0"
                    name="skill0"
                    className="form-control selectSkill"
                    value={this.state.skill0}
                    onChange={(e) => this.handleChange(e)}
                  >
                    <option value={""}>-Select skill- </option>
                    {this.state.skills.map((elem, index) => {
                      return (
                        <option key={elem.test + "0"} value={index}>
                          {elem.test}
                        </option>
                      );
                    })}
                  </select>
                </span>
              </div>
            </Form.Group>

            <button className="btn btn-m buttonSecondary paddingIdBtn">
              Next
            </button>
          </Form>
        </div>
      </Fragment>
    );
  }
}

export default JobForm;
