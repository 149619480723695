import React, { useState } from "react";
import Timeline from "./components/Timeline";
import { timelines } from "./utils";
import "./auth.css";

const Register = () => {
  const [selectTimeline, setSelectedTimeline] = useState(0);

  return (
    <div className="container-login">
      <div className="container-blue" style={{ padding: "50px" }}>
        <Timeline timelines={timelines} selectTimeline={selectTimeline} />

        <div className="step1Responsive">
          <h1>Sign up</h1>
          <p>
            We will requesting a form of ID later on, please use your name as it
            appears on.
          </p>
        </div>
      </div>
      <div className="rounded-lg container-form">
        {timelines[selectTimeline].content}
      </div>
    </div>
  );
};

export default Register;
