import React, { Component } from "react";

class RegisterCard extends Component {
  async componentDidMount() {
    try {
      const token = await window.location.href.split("/").pop();
      
      const response = await fetch(`/api/account/activate/${token}`);
      const data = await response.json();
      if (data.ok) {
        setTimeout(() => {
          window.location = "/login";
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  }
  render(props) {
    return (
      <div className="cardContainerPass">
        <h2 className="title passTitle">CONFIRM ACCOUNT</h2>
        <p className="extraText">
          Thank you for your account confirmation.
          <br />
          <br />
          You will be redirected to our login page in few seconds
        </p>
        <br />
        <p className="extraText">
          If you don't get redirected please click this link{" "}
          <a href="/login">Go to login</a>
        </p>
      </div>
    );
  }
}

export default RegisterCard;
