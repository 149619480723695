import React, { Component, Fragment } from "react";
import TermsCard from "./TermsCards";
import "./terms.css";
import StepsLine from "./timeline/StepsLine";

class Terms extends Component {
  render() {
    return (
      <div className="container-login">
        <div className="container-blue" style={{ padding: "10px" }}>
          <img
            className="imageTerms"
            src="/assets/images/terms.png"
            alt="Woman signing a contract"
          />
        </div>
        <div className="rounded-lg container-form">
          <TermsCard />
        </div>
      </div>
    );
  }
}

export default Terms;
