import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import "./skills.css";
class JobForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seniority0: "",
      skill0: "",
      seniority1: "",
      skill1: "",
      seniority2: "",
      skill2: "",
      iterator: 0,
      valid: "",
      skills: [],
      seniorityOptions0: [],
      seniorityOptions1: [],
      seniorityOptions2: [],
      next: false,
    };
  }
  async componentDidMount() {
    await fetch("/api/skills/getSkills")
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          skills: data,
        });
      });
    let data = [
      {
        id: 1,
        skill: "Javascript",
        seniority: [
          { seniority: "Basic" },
          { seniority: "Medium" },
          { seniority: "Advance" },
        ],
        test: "Javascript",
      },
      {
        id: 2,
        skill: "Node js",
        seniority: [
          { seniority: "Basic" },
          { seniority: "Medium" },
          { seniority: "Advance" },
        ],
        test: "Node js",
      },
      {
        id: 3,
        skill: "React Js",
        seniority: [
          { seniority: "Basic" },
          { seniority: "Medium" },
          { seniority: "Advance" },
        ],
        test: "React Js",
      },
    ];
    // await data.sort((a, b) => {
    //   if (a.test.toLowerCase() < b.test.toLowerCase()) {
    //     return -1;
    //   }
    //   if (a.test.toLowerCase() > b.test.toLowerCase()) {
    //     return 1;
    //   }
    //   return 0;
    // });
    // this.setState({
    //   skills: data,
    // });
  }

  handleOption = (event) => {
    const { name, type, checked } = event.target;

    this.setState({
      [name]: checked,
    });
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    console.log(value);
    await fetch(`/api/skills/getSkills/${value}`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          [name]: value,
          [`seniorityOptions${name?.split("")[5]}`]: data,
        });
      });
    // type === "checkbox"
    //   ? this.setState({
    //       [name]: checked,
    //     })
    //   : this.setState({
    //       [name]: value,
    //     });
  };

  handlePlus = () => {
    if (this.state.iterator < 2) {
      this.setState({
        iterator: this.state.iterator + 1,
      });
    } else {
      this.setState({
        valid: "You can log only three skills",
      });
    }
  };

  handleMinus = () => {
    if (this.state.iterator === 2) {
      this.setState({
        iterator: 1,
        seniority2: "",
        skill2: "",
      });
    } else if (this.state.iterator === 1) {
      this.setState({
        iterator: 0,
        seniority1: "",
        skill1: "",
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let basics = await fetch("/api/app/createBasics");
    basics = await basics.json();
    const skills = [];
    console.log(
      this.state.skills.find((item) => item?.skill === this.state.skill0)
    );
    if (this.state.skill0) {
      const skill0 = this.state.skills.find(
        (item) => item?.skill === this.state.skill0
      );
      skills.push({
        seniority: skill0?.seniority,
        skill: skill0?.skill,
        invitation: skill0?.assessment_url,
      });
    }
    if (this.state.skill1) {
      const skill1 = this.state.skills.find(
        (item) => item?.skill === this.state.skill1
      );
      skills.push({
        seniority: skill1?.seniority,
        skill: skill1?.skill,
        invitation: skill1?.assessment_url,
      });
    }
    if (this.state.skill2) {
      const skill2 = this.state.skills.find(
        (item) => item?.skill === this.state.skill2
      );
      skills.push({
        seniority: skill2?.seniority,
        skill: skill2?.skill,
        invitation: skill2?.assessment_url,
      });
    }

    for (let i = 0; i <= this.state.iterator; i++) {
      const dataFetch = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          seniority: skills[i].seniority,
          skill: skills[i].skill,
          invitation: skills[i].invitation,
        }),
      };
      const response = await fetch("/api/app/createSkill", dataFetch);
      const data = await response.json();
      if (data.ok) {
        this.setState({
          next: true,
        });
      } else {
        this.setState({
          next: false,
        });
        this.setState({ valid: data.error + " " + this.state.valid });
      }
    }
    if (this.state.next) {
      let data = {};
      if (basics.skill.score === "Not taken") {
        const response = await fetch(
          `/api/mails/sendSkills/${basics.skill._id}`
        );
        data = await response.json();
      } else {
        data = { ok: true };
      }
      if (data.ok) {
        this.props.handleSend(skills);
      } else {
        this.setState({ valid: data.error + this.state.valid });
      }
    }
  };

  render(props) {
    return (
      <Fragment>
        <div className="cardJob">
          <h2 className="titleRegister">SKILLS</h2>
          <p>Hello {this.props.name}</p>
          <p className="bottomTextJob textchekid">
            It is now time to tell us what you are good at it. Please note you
            will have to do a Test which could take up to 1 hour for each skill
            you log, so please focus on those you are really good at it.
          </p>
          <Form className="formJob" onSubmit={(e) => this.handleSubmit(e)}>
            <Form.Group className="">
              <div className="imageHandler">
                <div className="">
                  {this.state.skill0 !== "" && (
                    <span className="rowSkill">
                      <label className="marginLeftSkills">Seniority</label>
                      <select
                        id="seniority0"
                        name="seniority0"
                        className="form-control selectSkill"
                        value={this.state.seniority0}
                        onChange={(e) => this.handleOption(e)}
                      >
                        <option value={""}>-Select seniority level- </option>
                        {this.state.seniorityOptions0.map((elem, index) => {
                          return (
                            <option
                              key={elem.seniority + this.state.skill0}
                              value={index}
                            >
                              {elem.seniority}
                            </option>
                          );
                        })}
                      </select>
                    </span>
                  )}
                  <span className="rowSkill">
                    <label className="marginLeftSkills">Skill</label>
                    <select
                      id="skill0"
                      name="skill0"
                      className="form-control selectSkill"
                      value={this.state.skill0}
                      onChange={(e) => this.handleChange(e)}
                    >
                      <option value={""}>-Select skill- </option>
                      {this.state.skills.map((elem, index) => {
                        return (
                          <option key={elem.skill + "0"} value={elem?.skill}>
                            {elem.skill}
                          </option>
                        );
                      })}
                    </select>
                  </span>
                </div>
                {this.state.iterator === 0 ? (
                  <img
                    onClick={(e) => this.handlePlus(e)}
                    className="imgSkills"
                    alt="plus icon"
                    src="/assets/images/PLUS.png"
                  />
                ) : (
                  <img
                    onClick={(e) => this.handleMinus(e)}
                    className="imgSkills"
                    alt="plus icon"
                    src="/assets/images/minusIcon.png"
                  />
                )}
              </div>
            </Form.Group>
            <span>
              {this.state.iterator >= 1 && (
                <Form.Group className="">
                  <div className="imageHandler">
                    <div className="">
                      {this.state.skill1 !== "" && (
                        <span className="rowSkill">
                          <label className="marginLeftSkills">Seniority</label>
                          <select
                            id="seniority1"
                            name="seniority1"
                            className="form-control selectSkill"
                            value={this.state.seniority1}
                            onChange={(e) => this.handleOption(e)}
                          >
                            <option value={""}>
                              -Select seniority level-{" "}
                            </option>
                            {this.state.seniorityOptions1.map((elem, index) => {
                              return (
                                <option
                                  key={elem.seniority + this.state.skill1}
                                  value={index}
                                >
                                  {elem.seniority}
                                </option>
                              );
                            })}
                          </select>
                        </span>
                      )}
                      <span className="rowSkill">
                        <label className="marginLeftSkills">Skill</label>
                        <select
                          id="skill1"
                          name="skill1"
                          className="form-control selectSkill"
                          value={this.state.skill1}
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option value={""}>-Select skill- </option>
                          {this.state.skills.map((elem, index) => {
                            return (
                              <option
                                key={elem.skill + "1"}
                                value={elem?.skill}
                              >
                                {elem.skill}
                              </option>
                            );
                          })}
                        </select>
                      </span>
                    </div>
                    {this.state.iterator === 1 ? (
                      <img
                        onClick={(e) => this.handlePlus(e)}
                        className="imgSkills"
                        alt="plus icon"
                        src="/assets/images/PLUS.png"
                      />
                    ) : (
                      <span className="imgSkills">{"  "}</span>
                    )}
                  </div>
                </Form.Group>
              )}
            </span>
            <span>
              {this.state.iterator >= 2 && (
                <Form.Group className="">
                  <div className="imageHandler">
                    <div className="">
                      {this.state.skill2 !== "" && (
                        <span className="rowSkill">
                          <label className="marginLeftSkills">Seniority</label>
                          <select
                            id="seniority2"
                            name="seniority2"
                            className="form-control selectSkill"
                            value={this.state.seniority2}
                            onChange={(e) => this.handleOption(e)}
                          >
                            <option value={""}>
                              -Select seniority level-{" "}
                            </option>
                            {this.state.seniorityOptions2.map((elem, index) => {
                              return (
                                <option
                                  key={elem.seniority + this.state.skill2}
                                  value={index}
                                >
                                  {elem.seniority}
                                </option>
                              );
                            })}
                          </select>
                        </span>
                      )}
                      <span className="rowSkill">
                        <label className="marginLeftSkills">Skill</label>
                        <select
                          id="skill2"
                          name="skill2"
                          className="form-control selectSkill"
                          value={this.state.skill2}
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option value={""}>-Select skill- </option>
                          {this.state.skills.map((elem, index) => {
                            return (
                              <option
                                key={elem.skill + "2"}
                                value={elem?.skill}
                              >
                                {elem.skill}
                              </option>
                            );
                          })}
                        </select>
                      </span>
                    </div>
                    <span className="imgSkills">{"  "}</span>
                  </div>
                </Form.Group>
              )}
            </span>
            {this.state.valid ? (
              <p className="warning">{this.state.valid}</p>
            ) : null}
            <div className="skillButtomRow">
              <button
                className="btn btn-m buttonSecondary paddingIdBtn"
                onClick={(e) => {
                  e.preventDefault();
                  window.history.back();
                }}
              >
                Back
              </button>
              <button className="btn btn-m buttonSecondary paddingIdBtn">
                Next
              </button>
            </div>
          </Form>
        </div>
      </Fragment>
    );
  }
}

export default JobForm;
