import React, { Component } from "react";
import { Form } from "react-bootstrap";

class PasswordCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      valid: "",
      sent: false,
    };
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  handleSubmit = async (event) => {
    event.preventDefault();

    const emailPattern = new RegExp(
      // eslint-disable-next-line
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );
    if (this.state.email === "") {
      this.setState({
        valid: "Missing email",
      });
    } else if (!emailPattern.test(this.state.email)) {
      this.setState({
        valid: "Please enter a valid email address",
      });
    } else {
      const dataFetch = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: this.state.email,
        }),
      };
      const response = await fetch("/api/mails/reset-password", dataFetch);
      const data = await response.json();
      if (data.ok) {
        this.setState({
          valid: "",
        });
        this.setState({
          sent: true,
        });
      } else {
        this.setState({
          valid: "This email does not exist in our database",
        });
      }
    }
  };
  handleClick = async (event) => {
    event.preventDefault();

    const emailPattern = new RegExp(
      // eslint-disable-next-line
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );
    if (this.state.email === "") {
      this.setState({
        valid: "Missing email",
      });
    } else if (!emailPattern.test(this.state.email)) {
      this.setState({
        valid: "Please enter a valid email address",
      });
    } else {
      const dataFetch = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: this.state.email,
        }),
      };
      const response = await fetch("/api/mails/reset-password", dataFetch);
      const data = await response.json();
      if (data.ok) {
        this.setState({
          valid: "Email resent",
        });
        this.setState({
          sent: true,
        });
      } else {
        this.setState({
          valid: "This email does not exist in our database",
        });
      }
    }
  };
  render() {
    if (!this.state.sent) {
      return (
        <div className="cardContainerPass">
          <h2 className="title passTitle">FORGOT PASSWORD</h2>
          <Form className="authForm" onSubmit={(e) => this.handleSubmit(e)}>
            <Form.Group className="formInput">
              <Form.Control
                type="email"
                id="email"
                name="email"
                placeholder="Email Adress"
                required
                value={this.state.email}
                onChange={(e) => this.handleChange(e)}
              />
            </Form.Group>
            {this.state.valid ? (
              <p className="warning">{this.state.valid}</p>
            ) : null}
            <button className="col-5 btn btn-m buttonSecondary">
              Resend Password
            </button>
          </Form>
          <p className="bottomText">
            Back to<a href="/login"> Sign In</a>
          </p>
          <p>
            <a
              href="mailto:support@myteamabroad.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support
            </a>
          </p>
        </div>
      );
    } else {
      return (
        <div className="cardContainerPass">
          <h2 className="title passTitle">FORGOT PASSWORD</h2>
          <p className="extraText">
            An email have been sent to {this.state.email} with further
            instructions.
            <br />
            <br />
            Please remember to look in important, promotions and spam tags.
          </p>
          <br />
          <p className="smallText">Didn't receive a message?</p>
          <button
            className="col-5 btn btn-m buttonSecondary"
            onClick={(e) => this.handleSubmit(e)}
          >
            Resend Password
          </button>
          <p className="bottomText">
            Back to<a href="/login"> Sign In</a>
          </p>
          <p>
            <a
              href="mailto:support@myteamabroad.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support
            </a>
          </p>
        </div>
      );
    }
  }
}

export default PasswordCard;
