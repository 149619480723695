import React, { Component, Fragment } from "react";

class SkillResend extends Component {
  handleClick = async (event, id) => {
    event.preventDefault();
    const response = await fetch(`/api/mails/resendSkill/${id}`);
    const data = await response.json();
    if (data.ok) {
      window.location = "/dashboard";
    } else {
      window.location = `/resendskill/${data.skill._id}`;
    }
  };
  render(props) {
    return (
      <Fragment>
        <div className="cardJob">
          <h2 className="titleRegister">SKILLS</h2>
          <p>Hello {this.props.name}</p>
          <p className="bottomText textchekid marginEnglishText">
            You still have a pending test, you need to present the{" "}
            {this.props.skill} test in order to present other tests, look in
            your email inbox to find your test link, remember to search in your
            span, promotions and other folders of your mail provider
          </p>
          <img
            className="englishImage"
            alt="Woman taking test"
            src="/assets/images/mailsend.png"
          />
          <div className="buttomsRow">
            <button
              className="btn btn-m buttonSecondaryEng paddingIdBtn"
              onClick={(e) => {
                this.handleClick(e, this.props.id);
              }}
            >
              Resend Email
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SkillResend;
