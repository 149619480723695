import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Countries from "../utils/country";
import Dates from "../utils/dates";

class ProfileCard extends Component {
  constructor(props) {
    super(props);
    this.state = { focused: false };
  }

  handleFocus = (event) => {
    event.preventDefault();
    this.setState({
      focused: true,
    });
  };
  handleBlur = (event) => {
    event.preventDefault();
    this.setState({
      focused: false,
    });
  };

  render(props) {
    return (
      <div className="cardForm">
        <Form onSubmit={(e) => this.props.handleSubmit(e)}>
          <div className="CloudContainer">
            <label className="labelProfile" htmlFor="file">
              <img
                className="Profileicon"
                alt="User avatar"
                src={
                  this.props.user.avatar
                    ? this.props.user.avatar
                    : "/assets/images/avplaceholder.png"
                }
              />
              <span className="littleTag">+</span>
            </label>
          </div>
          {this.props.user.photo ? <p>{this.props.user.photo.name}</p> : null}
          <input
            type="file"
            name="photo"
            id="file"
            style={{ display: "none" }}
            onChange={(e) => this.props.handleUpload(e)}
          />

          <Form.Group className="formInput">
            <div className="PairMaker">
              <div className="widthDelProf">
                <label htmlFor="firstName"> First Name </label>
                <Form.Control
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  disabled="true"
                  onChange={(e) => this.props.handleChange(e)}
                  value={this.props.user.firstName}
                />
              </div>
              <div className="widthDelProf">
                <label htmlFor="lastName"> Last Name </label>
                <Form.Control
                  type="text"
                  id="lastName"
                  name="lastName"
                  disabled="true"
                  placeholder="Last Name"
                  onChange={(e) => this.props.handleChange(e)}
                  value={this.props.user.lastName}
                />
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <div>
              <label htmlFor="address">Address</label>
              <Form.Control
                type="text"
                id="address"
                name="address"
                placeholder="Address"
                onChange={(e) => this.props.handleChange(e)}
                value={this.props.user.address}
              />
            </div>
          </Form.Group>

          <Form.Group className="formInput">
            <div className="PairMaker">
              <Form.Control
                className="widthDel"
                type="text"
                id="postcode"
                name="postcode"
                placeholder="Postcode"
                onChange={(e) => this.props.handleChange(e)}
                value={this.props.user.postcode}
              />

              <select
                id="tempCountry"
                name="tempCountry"
                className="form-control widthDel marginLeft"
                disabled="true"
                value={this.props.user.tempCountry}
                onChange={(e) => this.props.handleSelect(e)}
              >
                <option value="-Select your country-">
                  -Select your country-
                </option>
                {Countries.map((country) => {
                  return (
                    <option
                      key={country.name + country.value}
                      value={country.name + "/" + country.mcode}
                    >
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </Form.Group>
          <Form.Group className="formInput">
            <div>
              <label>Phone Number</label>
              <div className="PairMaker">
                <select
                  id="countryCode"
                  name="countryCode"
                  disabled="true"
                  onChange={(e) => this.props.handleChange(e)}
                  value={this.props.user.countryCode}
                  className="form-control numericSelect"
                >
                  <option value="+">+</option>
                  {Countries.map((country) => {
                    return (
                      <option
                        key={country.value + country.name}
                        value={country.mcode}
                      >
                        {country.mcode + " " + country.ccode}
                      </option>
                    );
                  })}
                </select>
                <Form.Control
                  className="phoneInput"
                  type="number"
                  id="phone"
                  name="phone"
                  disabled="true"
                  placeholder="Phone number"
                  onChange={(e) => this.props.handleChange(e)}
                  value={this.props.user.phone}
                />
              </div>
            </div>
          </Form.Group>
          <Form.Group className="formInput">
            <label>Date of birth</label>
            <div className="PairMaker">
              <select
                id="year"
                name="year"
                disabled="true"
                value={this.props.user.year}
                onChange={(e) => this.props.handleChange(e)}
                className="form-control dateSelect
									marginLeft"
              >
                <option value="year">Year</option>
                {Dates.year.map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
              <select
                id="month"
                name="month"
                disabled="true"
                value={this.props.user.month}
                onChange={(e) => this.props.handleChange(e)}
                className="form-control dateSelect
									marginLeft"
              >
                <option value="month">Month</option>
                {Dates.month.map((month) => {
                  return (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  );
                })}
              </select>
              <select
                id="day"
                name="day"
                disabled="true"
                value={this.props.user.day}
                onChange={(e) => this.props.handleChange(e)}
                className="form-control dateSelect
									marginLeft"
              >
                <option value="day">Day</option>
                {Dates.day.map((day) => {
                  return (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  );
                })}
              </select>
            </div>
          </Form.Group>
          <Form.Group className="formInput">
            <div className="PairMaker">
              <div className="widthDelProf">
                <label htmlFor="email"> Email </label>
                <Form.Control
                  type="email"
                  id="email"
                  name="email"
                  disabled="true"
                  placeholder="Email"
                  onChange={(e) => this.props.handleChange(e)}
                  value={this.props.user.email}
                />
              </div>
              <div className="widthDelProf">
                <label htmlFor="password"> Password </label>
                <Form.Control
                  type={this.state.focused ? "text" : "password"}
                  onFocus={(e) => this.handleFocus(e)}
                  onBlur={(e) => this.handleBlur(e)}
                  id="password"
                  name="password"
                  placeholder="Password"
                  readOnly
                  value={this.props.user.password}
                />
              </div>
            </div>
          </Form.Group>

          <Form.Group className="formInput">
            <div className="PairMaker">
              <div className="widthDelProf">
                <label htmlFor="newPassword"> New Password </label>
                <Form.Control
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="New Password"
                  onChange={(e) => this.props.handleChange(e)}
                  value={this.props.user.newPassword}
                />
              </div>

              <div className="widthDelProf">
                <label htmlFor="newPasswordConfirm">
                  {" "}
                  New Password Confirm
                </label>
                <Form.Control
                  type="password"
                  id="newPasswordConfirm"
                  name="newPasswordConfirm"
                  placeholder="New Password Confirm"
                  onChange={(e) => this.props.handleChange(e)}
                  value={this.props.user.newPasswordConfirm}
                />
              </div>
            </div>
          </Form.Group>
          {this.props.user.valid ? (
            <p className="warning">{this.props.user.valid}</p>
          ) : null}
          <div className="contentButton">
            <button className="col-5 btn btn-m buttonSecondary">Save</button>
          </div>
        </Form>
      </div>
    );
  }
}

export default ProfileCard;
