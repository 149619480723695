import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";

class DreamjobDash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      low: "",
      high: "",
      position: "",
      modality: "",
    };
  }
  async componentDidMount() {
    const response = await fetch("/api/app/current-user/job");
    const data = await response.json();
    if (data.error) {
      window.location = "/login";
    } else {
      if (!data.job) window.location = "/dreamjob";
      this.setState({
        low: parseInt(data.job.salaryLow, 10),
        high: parseInt(data.job.salaryHigh, 10),
        position: data.job.position,
        modality: data.job.modality,
      });
    }
  }

  render(props) {
    return (
      <Fragment>
        <div className="cardJobDash">
          <h2 className="titleRegister">YOUR DREAM JOB</h2>
          <p className="bottomText textchekid">
            It is now time to tell us what your dream job looks like
          </p>
          <Form className="formJob">
            <Form.Group className="formInput">
              <div className="wrapInputs">
                <div>
                  <p className="firstDashLabel">Desire monthly salary range</p>
                  <Form.Control
                    className="widthDelDash"
                    type="number"
                    id="low"
                    name="low"
                    placeholder="USD"
                    disabled
                    value={this.state.low}
                  />
                </div>

                <div className="inputMarginTop">
                  <Form.Control
                    className="widthDelDash"
                    type="number"
                    id="high"
                    name="high"
                    placeholder="USD"
                    disabled
                    value={this.state.high}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group
              // className="PairMakerJobSelect"
              style={{
                width: "95%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "auto",
                alignItems: "center"
              }}
            >
              <label>Part/Full time</label>
              <select
                id="modality"
                name="modality"
                className="form-control selectJob"
                disabled
                value={this.state.modality}
              >
                <option value="Full time">Full time</option>
                <option value="Part time">Part time</option>
              </select>
            </Form.Group>
          </Form>
          <a href="/dreamjob">
            <span className="editJob">Edit</span>
            <img
              className="rounded-circle littleImage editJobImage"
              alt="edit icon"
              src="/assets/images/edit.png"
            />
          </a>
        </div>
      </Fragment>
    );
  }
}

export default DreamjobDash;
