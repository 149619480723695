import React from "react";
import PasswordCard from "./PhoneCheckCard";
import "./phonecheck.css";

const propsValidator = async (props) => {
  if (props.data.name) {
    await fetch("/api/phone/sendcode");
  }
};
const FPassword = (props) => {
  propsValidator(props);
  return (
    <div className="container-login">
      <div className="container-blue">
        <img
          className="imageAuthLogin"
          src="/assets/images/password.png"
          alt="Woman defending her computer"
        />
      </div>
      <div className="container-form">
        <PasswordCard />
      </div>
    </div>
  );
};

export default FPassword;
